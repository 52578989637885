const ShareIcon = ({ width = 15, height = 16, color = '#999999' }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox='0 0 15 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Share</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Icon/share'
          transform='translate(1.000000, -1.000000)'
          fillRule='nonzero'
        >
          <g id='Share' transform='translate(0.000000, -2.000000)'>
            <path
              d='M9.80611718,2.12737342 L9.94080609,2.21783019 L10.0248959,2.2932895 L10.1107636,2.39129951 L13.1107636,6.30159743 C13.4469396,6.73978021 13.3642468,7.36752202 12.9260641,7.70369802 C12.5215876,8.01401433 11.9555935,7.96742465 11.6064993,7.6137782 L11.5239635,7.51899842 L10.317,5.946 L10.3173636,13.0625 C10.3173636,13.6147847 9.86964831,14.0625 9.31736356,14.0625 C8.80452772,14.0625 8.3818564,13.6764598 8.32409129,13.1791211 L8.31736356,13.0625 L8.317,5.945 L7.11076365,7.51899842 C6.77458765,7.95718119 6.14684583,8.03987402 5.70866306,7.70369802 C5.30418666,7.39338171 5.20261293,6.83463063 5.45379108,6.4058625 L5.52396346,6.30159743 L8.55334007,2.35455428 L8.60649927,2.29651972 L8.64960621,2.25564074 L8.70866306,2.20659991 L8.81292813,2.13642752 L8.92260914,2.08095313 L9.05454904,2.03489399 L9.19917066,2.00691155 L9.2786523,2.00067817 L9.38921378,2.00257378 L9.50626532,2.01799306 L9.65621302,2.0588699 L9.73165122,2.08963693 L9.80611718,2.12737342 Z'
              id='Combined-Shape'
              fill={color}
              transform='translate(9.317364, 8.031589) rotate(-320.000000) translate(-9.317364, -8.031589) '
            ></path>
            <path
              d='M3.11250811,4.74280105 C3.66479286,4.74280105 4.11250811,5.1905163 4.11250811,5.74280105 C4.11250811,6.25563689 3.72646792,6.67830821 3.22912923,6.73607332 L3.11250811,6.74280105 L1,6.742 L1,16.742 L11,16.742 L11,13.6841238 C11,13.171288 11.3860402,12.7486166 11.8833789,12.6908515 L12,12.6841238 C12.5128358,12.6841238 12.9355072,13.070164 12.9932723,13.5675027 L13,13.6841238 L13,17.742801 C13,18.2556369 12.6139598,18.6783082 12.1166211,18.7360733 L12,18.742801 L0,18.742801 C-0.512835839,18.742801 -0.935507161,18.3567609 -0.993272269,17.8594222 L-1,17.742801 L-1,5.74280105 C-1,5.22996521 -0.61395981,4.80729389 -0.116621125,4.74952878 L0,4.74280105 L3.11250811,4.74280105 Z'
              id='Path-5'
              fill={color}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ShareIcon;
