import { useContext, useEffect } from 'react'

import { AppContext } from 'contexts'

import Header from 'components/header/Header'
import Sidebar from 'components/sidebar/Sidebar'

import styles from './Wrapper.module.scss'


let LoadedWrapper = () => <Wrapper/>
export default function Wrapper({ children, hasSearchBar = true }) {
  // if this is rendered, we're logged in and bound to a team; use this as the
  // default wrapper for future loading screens, though we ignore the children
  // as this should be a _very_ temporary state once loaded and the only purpose
  // of it is to prevent FOUC-like behavior
  let { setLoadingWrapper }  = useContext(AppContext)
  useEffect(() => setLoadingWrapper(() => LoadedWrapper), [/*immutable: */ setLoadingWrapper])

  return <div className={styles.container}>
    <Header hasSearchBar={hasSearchBar} />
    <Sidebar />
    <div className={styles.scrollContainer}>{children}</div>
  </div>
}
