import { useContext, useMemo, useState } from 'react'
import { SessionContext, ResourceContext } from 'contexts'

import AddFolderIcon from 'components/icons/AddFolderIcon'
import GridIcon from 'components/icons/GridIcon'
import IconButton from '@mui/material/IconButton'
import ListIcon from 'components/icons/ListIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import UploadIcon from 'components/icons/UploadIcon'

import styles from './MediaBrowserHeader.module.scss'


function Breadcrumb({id, onNavigate, isMenuOption = false}) {
  let { user } = useContext(SessionContext)
  let { useResource } = useContext(ResourceContext)
  let [folder] = useResource(id)
  return <>
    <h1 className={isMenuOption ? styles.menuPath : styles.linkedPath} onClick={() => onNavigate(id)}>
      {id == user.home ? 'Home' : folder?.name ?? '...'}
    </h1>
    {!isMenuOption && (
      <h1 className={styles.splitLine}>&nbsp;/&nbsp;</h1>
    )}
  </>
}


export default function MediaBrowserHeader({
  folder,
  view,
  onNavigate,
  // optional controls
  onToggleView,
  onCreate,
  onUpload,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  let readOnly = useMemo(() => !folder?.has('write'), [folder])

  let hiddenPaths = useMemo(() => {
    let hiddenPaths = [];
    if (folder?.hierarchy?.length > 1) {
      folder.hierarchy.map((id, index) => {
        if (index < folder.hierarchy.length - 1) {
          hiddenPaths.unshift(id)
        }
      })
    }
    return hiddenPaths
  }, [folder?.hierarchy])

  return (
    <div className={styles.container}>
      <div className={styles.pathWrapper}>
        { folder?.hierarchy?.length === 1 && (
          <Breadcrumb id={folder?.hierarchy?.[0]} onNavigate={onNavigate}/>
        )}
        { folder?.hierarchy?.length > 1 && (
          <>
            <h1 className={styles.linkedPath} onClick={(event) => setAnchorEl(event.currentTarget)}>...</h1>
            <h1 className={styles.splitLine}>&nbsp;/&nbsp;</h1>
            <Breadcrumb id={folder?.hierarchy?.[folder.hierarchy.length - 1]} onNavigate={onNavigate}/>
          </>
        )}
        <h1 className={styles.unlinkedPath}>{folder ? folder.name ?? 'Home' : '...'}</h1>
        <Menu
          id='hidden-paths'
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
        {hiddenPaths.map(id =>
          <MenuItem key={id}>
            <Breadcrumb
              isMenuOption
              id={id}
              onNavigate={(event) => {
                onNavigate(event)
                setAnchorEl(null)
              }}
            />
          </MenuItem>
        )}
        </Menu>
      </div>
      <div className={styles.rightSide}>
        {onCreate &&
          <IconButton
            onClick={onCreate}
            disabled={readOnly}
          >
            <AddFolderIcon color={readOnly ? '#ccc' : '#000'} />
          </IconButton>
        }
        {onUpload &&
          <IconButton
            className={styles.uploadIcon}
            disabled={readOnly}
            onClick={onUpload}
          >
            <UploadIcon color={readOnly ? '#ccc' : '#000'} />
          </IconButton>
        }
        {onToggleView &&
          <>
            <div className={styles.pipe}></div>
            <IconButton onClick={onToggleView}>
              {view == 'list' ? <ListIcon /> : <GridIcon />}
            </IconButton>
          </>
        }
      </div>
    </div>
  )
}
