// app version; used to determine whether we should flush the cache on load
export const APP_VERSION = 6 // please update this whenever you alter the cache schema

export const PUBLIC_PATHS = new Set([
  // the following paths are public and may be accessed without a valid token
  '/auth/reset-password',
  '/auth/set-password',
  '/privacy',
  '/terms',
])
export const PERSISTED_KEYS = new Set([
  // the following keys will not be evicted from the localStorage-backed cache
  // on memory pressure
  'token', // used to remember whether the user is logged in or not
  'version' // keeps the cache schema format; will probably always be 'version'
])
export const NAVBAR_HIDE_DELAY = 333 // hide the navbar on navigation after 0.3s
export const SNACK_HIDE_DELAY = 5_000 // hide the snack message after 5 seconds

// api config
export const API_URL = process.env.API_URL // which api to use
export const API_CLOCK_DRIFT = 5_000 // assume the local clock drifts from server by 5s
export const SHA256_WASM_PATH = '/sha256.wasm' // location of the sha256 wasm blob

// cache config
export const MAX_CACHE_LIVE_REFRESH = 24 * 3600_000 // don't set a timer for longer than 1 day

// TODO: algorithmically throttle these depending on user interaction and update
// frequency (e.g. stop fetching if the user has been inactive for a while, or
// the resource was not updated for a long time but reset them once the user is
// back online)
export const CONFIG_LIFETIME = 3600_000 // cache app config for one hour
export const SESSION_LIFETIME = 900_000 // cache sessions for 15 minutes
export const FOLDER_LIFETIME = 60_000 // refresh folders every 60 seconds
export const FILE_LIFETIME = 3600_000  // cache files for one hour
export const TEAM_LIFETIME = 3600_000 // cache teams for one hour
export const MEMBER_LIFETIME = 3600_000 // cache members and avatars for one hour
export const ARTICLE_LIFETIME = 60_000 // cache articles for one minute
export const STATS_LIFETIME = 60_000 // cache stats for one minute

// media config
export const UPLOAD_CONCURRENCY = 2
