import { useContext, useMemo } from 'react'
import { ResourceContext, SessionContext } from 'contexts'
import { MEMBER_LIFETIME } from 'constants'

import Link from 'next/link'

import styles from './HeaderUser.module.scss'


export default function HeaderUser() {
  const { user } = useContext(SessionContext)
  const { useResource } = useContext(ResourceContext)
  const [avatar] = useResource(user.avatar, MEMBER_LIFETIME)
  const [name, userType] = useMemo(() => [
    user.name.toUpperCase(),
    (user.isStaff ? 'Padcaster' : 'Team') + ' ' + (user.isAdmin ? 'Admin' : 'Member'),
  ], [user])

  return (
    <Link href={'/account'} passHref>
      <a className={styles.container}>
        <div className={styles.userNameWrapper}>
          <p className={styles.userName}>{name}</p>
          <p className={styles.userType}>{userType}</p>
        </div>
        <div
          className={styles.userAvatar}
          style={avatar ? { backgroundImage: `url(${avatar.url})`} : {}}
        >
          {!avatar && name[0]}
        </div>
      </a>
    </Link>
  )
}
