import { useContext, useMemo, useState } from 'react'
import { ResourceContext } from 'contexts'

import MediaBrowser from 'components/mediabrowser/MediaBrowser'
import Modal from 'components/modal/Modal'


export default function MediaBrowserModal({
  // context
  initialLocation,
  view,
  filter,
  accept=() => true,
  preview,

  // customization
  title = 'Choose a location',
  action = 'Save here',
  maxWidth = 'sm',

  // callbacks
  onConfirm,
  onCreate,
  onUpload,
  onClose,
}){
  initialLocation = initialLocation?.id ?? initialLocation
  let { useResource } = useContext(ResourceContext)

  let [location, setLocation] = useState(initialLocation)

  let [folder] = useResource(location)
  let locationDisabled = useMemo(() => !(folder && accept(folder)), [folder, accept])
  let [disabled, setDisabled] = useState(false) // spam protection

  async function onAction() {
    setDisabled(true)
    try {
      await onConfirm(location)
    } catch(err) {
      console.error(err)
    } finally {
      setDisabled(false)
      onClose()
    }
  }

  return (
    <Modal
      title={title}
      open={true}
      handleClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      primaryFooterAction={{
        content: action,
        disabled: disabled || locationDisabled,
        onAction,
      }}
    >
      {location && <MediaBrowser
          view={view}
          location={location}
          preview={preview}
          filter={filter}
          modalHeight={maxWidth == 'sm' ? '200px' : '431px'}
          onNavigate={target => setLocation(target.id ?? target)}
          onCreate={onCreate}
          onUpload={onUpload}
      />}
    </Modal>
  )
}
