import CloseIcon from 'components/icons/CloseIcon'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'

import styles from './UploadItem.module.scss'


// TODO:
// - Style differently for success and error
export default function UploadItem({
  name,
  transferred,
  size,
  status,
  onAbort,
  onDelete,
}) {
  return (
    <div className={styles.container}>
      <label className={styles.name}>{name}</label>
      <div className={styles.progressContainer}>
        <LinearProgress
          className={styles.progress}
          color={status == 'success' || status == 'error' ? status : 'primary'}
          variant='determinate'
          value={transferred * 100 / size}
        />
        <IconButton sx={{ padding: 0 }} onClick={status == 'pending' ? onAbort : onDelete}>
          <CloseIcon />
        </IconButton>
      </div>

      {/* {file.status === 'cancelled' ? <small>Cancelled</small> : null}
      {file.status === 'error' ? <small>Error</small> : null}
      {file.status === 'success' ? <small>Success</small> : null} */}
    </div>
  )
}
