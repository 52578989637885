import { useContext } from 'react'
import { AppContext } from 'contexts'

import IconNav from 'components/icons/IconNav'

import styles from './HeaderSidebarFlyout.module.scss'


export default function HeaderSidebarFlyout() {
  let { navOpen, setNavOpen } = useContext(AppContext)
  return (
    <div
      className={navOpen ? styles.containerNavOpen : styles.container}
      onClick={() => setNavOpen(!navOpen)}
    >
      <IconNav />
    </div>
  )
}
