import { createContext, useEffect, useMemo, useState } from 'react'
import Router from 'next/router'


function useMediaQuery(query) {
  let mediaQuery = useMemo(() => window.matchMedia(query), [query])
  let [state, setState] = useState(mediaQuery.matches)

  useEffect(() => {
    let handler = event => setState(event.matches)

    return mediaQuery.addEventListener
      ? (mediaQuery.addEventListener('change', handler),
        () => mediaQuery.removeEventListener('change', handler))
      : (mediaQuery.addListener(handler),
        () => mediaQuery.removeListener(handler))
  }, [mediaQuery])

  return state
}


function matchItem(string, data) {
  // Source: https://medium.com/creative-technology-concepts-code/detect-device-browser-and-version-using-javascript-8b511906745
  // TODO: this is probably dogshit, consider replacements
  for (let {name, version, value} of data)
    if(string.match(new RegExp(value, 'i'))) {
      let matches = string.match(new RegExp(version + '[- /:;]([\\d._]+)', 'i'))
      if(matches && matches[1])
        matches = matches[1].split(/[._]+/).join('.')

      return {name, version: parseFloat(matches ?? 0)}
    }
  return null
}


function useQueryParam(name, defaultValue=null) {
  // returns and extracts the query param `name` from the current url; the value
  // will persist over the component's lifetime, but not across refreshes

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let cachedDefault = useMemo(() => defaultValue, [])
  let [value, setValue] = useState()
  useEffect(() => {
    let [location, query] = Router.asPath.split('?')
    let params = new URLSearchParams(query)
    let value = params.get(name)
    if(value !== null) {
      params.delete(name)
      query = params.toString()
      if(query)
        location += '?' + query
      Router.replace(location)
      setValue(value)
    }
  }, [name])
  return value ?? cachedDefault
}


export const DeviceContext = createContext()
export default function DeviceProvider({ children }) {
  //console.log('device refresh')
  const isDesktop = useMediaQuery('(hover: hover)')
  const isTouch = !isDesktop

  const isLandscape = useMediaQuery('(orientation: landscape)')
  const isPortrait = useMediaQuery('(orientation: portrait)')

  const isXSmall = useMediaQuery('(max-width: 600px)')
  const isSmall = useMediaQuery('(min-width: 600px)')
  const isMedium = useMediaQuery('(min-width: 768px)')
  const isLarge = useMediaQuery('(min-width: 992px)')
  const isXLarge = useMediaQuery('(min-width: 1200px)')

  const isMobile = (isSmall || isXSmall) && isTouch
  const isTablet = isMedium && isTouch

  let agent = useMemo(() => [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
    window.opera,
  ].join(' '), [])

  let [name, device] = useMemo(() => {
    let browser = matchItem(agent, [
      { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
      { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
      { name: 'Safari', value: 'Safari', version: 'Version' },
      { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
      { name: 'Opera', value: 'Opera', version: 'Opera' },
      { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
      { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
    ])
    let device = matchItem(agent, [
      { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
      { name: 'Windows', value: 'Win', version: 'NT' },
      { name: 'iPhone', value: 'iPhone', version: 'OS' },
      { name: 'iPad', value: 'iPad', version: 'OS' },
      { name: 'Kindle', value: 'Silk', version: 'Silk' },
      { name: 'Android', value: 'Android', version: 'Android' },
      { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
      { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
      { name: 'Macintosh', value: 'Mac', version: 'OS X' },
      { name: 'Linux', value: 'Linux', version: 'rv' },
      { name: 'Palm', value: 'Palm', version: 'PalmOS' },
    ])
    return browser && device
      ? [`${browser.name} ${browser.version}`, `${device.name} ${device.version}`]
      : ['', '']
  }, [agent])

  return (
    <DeviceContext.Provider value={{
      // hooks
      useQueryParam,

      // session name
      name,
      device,

      // properties
      isDesktop, isTouch,
      isLandscape, isPortrait,
      isXSmall, isSmall, isMedium, isLarge, isXLarge,
      isMobile, isTablet,
    }}>
      {children}
    </DeviceContext.Provider>
  )
}
