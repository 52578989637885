import MediaBrowserListItem from './MediaBrowserListItem'
import MediaBrowserListItemHeader from './MediaBrowserListItemHeader'

import styles from './MediaBrowserList.module.scss'


export default function MediaBrowserList({
  // navigation
  contents,
  selection,
  onNavigate,
  onDownload,
  onSort,
  sortKey,
  sortOrder,

  // selection management
  onToggleSelection,
  onToggleSelectAll,

  // mutators
  onShare,
  onCopy,
  onRename,
  onMove,
  onDelete,
}) {
  return (
    <div className={styles.container}>
      {onToggleSelectAll && <MediaBrowserListItemHeader
        onSort={onSort}
        sortKey={sortKey}
        sortOrder={sortOrder}
        contents={contents}
        selection={selection}
        onToggleSelectAll={onToggleSelectAll}
      />}
      {contents.map(resource =>
        <MediaBrowserListItem
          key={resource.id}
          resource={resource}
          selection={selection}
          onNavigate={onNavigate ? () => onNavigate(resource) : null}
          onDownload={onDownload ? () => onDownload(resource) : null}

          onToggleSelection={onToggleSelection ? () => onToggleSelection(resource) : null}

          onShare={onShare ? () => onShare(resource) : null}
          onCopy={onCopy ? () => onCopy(resource) : null}
          onRename={onRename ? () => onRename(resource) : null}
          onMove={onMove ? () => onMove(resource) : null}
          onDelete={onDelete ? () => onDelete(resource) : null}
        />
      )}
    </div>
  )
}
