const FolderIcon = ({ width = 14, height = 12, color = '#383838' }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox='0 0 15 13'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>folder</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='List/Item-folder'
          transform='translate(-2.000000, -1.000000)'
          fill={color}
          fillRule='nonzero'
        >
          <path
            d='M6.91815439,1.51484805 L4.95714936,1.54846512 C3.59334335,1.57184454 2.5,2.68409147 2.5,4.04809785 L2.5,11 C2.5,12.3807119 3.61928813,13.5 5,13.5 L14,13.5 C15.3807119,13.5 16.5,12.3807119 16.5,11 L16.5,5.7238806 C16.5,4.34316872 15.3807119,3.2238806 14,3.2238806 L8.62518099,3.2238392 C8.43485945,3.22194048 8.27814338,3.06210667 8.28006635,2.86686464 L8.28000471,2.83747283 C8.26725896,2.09131094 7.6575692,1.50217243 6.91815439,1.51484805 Z M4.97428961,2.54831821 L6.93529464,2.51465031 C7.12250584,2.51149182 7.27687217,2.66065482 7.28008149,2.84786602 L7.28009393,2.86376336 C7.27275261,3.60451595 7.8727528,4.21645271 8.62025279,4.22381495 L14,4.2238806 C14.8284271,4.2238806 15.5,4.89545347 15.5,5.7238806 L15.5,11 C15.5,11.8284271 14.8284271,12.5 14,12.5 L5,12.5 C4.17157288,12.5 3.5,11.8284271 3.5,11 L3.5,4.04809785 C3.5,3.22969402 4.15600601,2.56234586 4.97428961,2.54831821 Z'
            id='folder'
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default FolderIcon;
