function PlayIcon() {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='25' cy='25' r='24' stroke='white' strokeWidth='2' />
      <path
        d='M32.1466 24.3419C32.7206 24.7396 32.7206 25.5882 32.1466 25.9859L22.1976 32.8787C21.5344 33.3382 20.6281 32.8636 20.6281 32.0567V18.2711C20.6281 17.4643 21.5344 16.9896 22.1976 17.4491L32.1466 24.3419Z'
        fill='white'
      />
    </svg>
  );
}

export default PlayIcon;
