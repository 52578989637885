import { useTheme } from '@mui/material/styles'
import { useMemo } from 'react'

import Button from '@mui/material/Button'
import CopyIcon from '@mui/icons-material/ContentCopyOutlined'
import DownloadIcon from 'components/icons/DownloadIcon'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMoveOutlined'
import ShareIcon from 'components/icons/ShareIcon'
import TrashIcon from 'components/icons/TrashIcon'

import styles from './SelectedMediaActions.module.scss'


export default function SelectedMediaActions({
  folder,
  selection,
  onAbort,

  onDownload,
  onShare,
  onCopy,
  onMove,
  onDelete,
}) {
  let theme = useTheme()

  let size = useMemo(() => Object.keys(selection).length, [selection])

  // the 'shared' folder may not be copied, but anything else is a-ok provided
  // that it's readable
  let copyDisabled = useMemo(() => !!selection['shared'], [selection])
  // the 'shared' folder is not a real folder and cannot preserve the filesystem
  // invariant of unique names for each downloaded file; until such a time that
  // the API can supply unique names for shared content, any zip download that
  // includes the shared folder in some way is prohibited
  let downloadDisabled = useMemo(() =>
    (folder.id == 'shared' && size > 1) || !!selection['shared']
  , [folder, size, selection])
  let deleteDisabled = useMemo(() => {
    for(let resource of Object.values(selection))
      if(!resource.has('delete'))
        return true
    return false
  }, [selection])

  let shareDisabled = useMemo(() => {
    // Due to UX difficulties in presenting the existing sharing status across
    // multiple files, we currently only support sharing one file at a time
    // this might be removed in future releases
    let resources = Object.values(selection)
    return resources.length > 1 || resources.some(resource => !resource.has('share'))
  }, [selection])

  let buttonColor = flag => flag ? theme.palette.grey.main : theme.palette.primary.main
  return (
    <div className={styles.container}>
      <div className={styles.selectedCountWrapper}>
        <small className={styles.selectedCount}>{size} Selected</small>
        <span className={styles.cancelButton}>
          <Button variant='text' size='small' onClick={onAbort}>
            Cancel
          </Button>
        </span>
      </div>
      <div className={styles.rightSideButtons}>
        <Button
          className={styles.actionButton}
          variant='text'
          size='small'
          startIcon={<ShareIcon color={buttonColor(shareDisabled)}/>}
          onClick={onShare}
          disabled={shareDisabled}
        >
          <span className={styles.buttonText}>Share</span>
        </Button>
        <Button
          className={styles.actionButton}
          variant='text'
          size='small'
          startIcon={<DownloadIcon color={buttonColor(downloadDisabled)}/>}
          onClick={onDownload}
          disabled={downloadDisabled}
        >
          <span className={styles.buttonText}>Download</span>
        </Button>
        <Button
          className={styles.actionButton}
          variant='text'
          size='small'
          startIcon={<CopyIcon color={buttonColor(downloadDisabled)}/>}
          onClick={onCopy}
          disabled={copyDisabled}
        >
          <span className={styles.buttonText}>Copy</span>
        </Button>
        <Button
          className={styles.actionButton}
          variant='text'
          size='small'
          startIcon={<DriveFileMoveIcon color={buttonColor(deleteDisabled)}/>}
          onClick={onMove}
          disabled={deleteDisabled}
        >
          <span className={styles.buttonText}>Move</span>
        </Button>
        <Button
          className={styles.actionButton}
          variant='text'
          size='small'
          startIcon={<TrashIcon color={buttonColor(deleteDisabled)}/>}
          onClick={onDelete}
          disabled={deleteDisabled}
        >
          <span className={styles.buttonText}>Delete</span>
        </Button>
      </div>
    </div>
  )
}
