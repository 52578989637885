const AudioIcon = ({ width = 14, height = 12, color = '#333333' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 14 12`}
      version='1.1'
    >
      <title>Audio</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='video-image-copy'
          transform='translate(0.000000, 0.500000)'
          fill='#333333'
          fillRule='nonzero'
        >
          <g id='Group-14'>
            <path
              d='M12.0555556,0 L1.94444444,0 C0.870557431,0 0,1.01824131 0,2.27430563 L0,8.64236138 C0,9.89842569 0.870557431,10.916667 1.94444444,10.916667 L12.0555556,10.916667 C13.1294426,10.916667 14,9.89842569 14,8.64236138 L14,2.27430563 C14,1.01824131 13.1294426,0 12.0555556,0 Z M1.94444444,0.90972225 L12.0555556,0.90972225 C12.6998878,0.90972225 13.2222222,1.52066704 13.2222222,2.27430562 L13.2222222,8.64236137 C13.2222222,9.39599996 12.6998878,10.0069447 12.0555556,10.0069447 L1.94444444,10.0069447 C1.30011224,10.0069447 0.777777778,9.39599996 0.777777778,8.64236137 L0.777777778,2.27430562 C0.777777778,1.52066704 1.30011224,0.90972225 1.94444444,0.90972225 Z'
              id='Rectangle'
              fill={color}
            />
            <line
              x1='4'
              y1='5'
              x2='4'
              y2='6'
              id='Path-28'
              stroke='#333333'
              strokeLinecap='round'
            />
            <line
              x1='6'
              y1='3'
              x2='6'
              y2='8'
              id='Path-28-Copy'
              stroke='#333333'
              strokeLinecap='round'
            />
            <line
              x1='10'
              y1='4'
              x2='10'
              y2='7'
              id='Path-28-Copy-2'
              stroke='#333333'
              strokeLinecap='round'
            />
            <line
              x1='8'
              y1='2'
              x2='8'
              y2='9'
              id='Path-28-Copy-3'
              stroke='#333333'
              strokeLinecap='round'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AudioIcon;
