import { createContext, useCallback, useContext, useEffect, useMemo } from 'react'

import { ApiContext, CacheContext, SessionContext } from 'contexts'
import { FOLDER_LIFETIME, FILE_LIFETIME } from 'constants'
import { TaskQueue, patchObject } from 'utils'


function useResource(id, staleAfter=FILE_LIFETIME) {
  let { useCache } = useContext(CacheContext)
  let { _resourceKeyFor, _resourceQueue, _addResourceHelpers } = useContext(ResourceContext)
  let result = useCache(_resourceKeyFor(id), staleAfter, null, _addResourceHelpers)

  // reload when stale
  let stale = result[1]
  useEffect(() => {
    id && stale && _resourceQueue.set(id, _resourceQueue.get(id) || false)
  }, [id, stale, /*immutable: */ _resourceQueue])

  return result
}


function useResourceChildren(id, staleAfter=FOLDER_LIFETIME) {
  let { useCache, useCacheMap } = useContext(CacheContext)
  let { _childrenKeyFor, _resourceKeyFor, _resourceQueue, _addResourceHelpers } = useContext(ResourceContext)

  let [ids, idsStale, idsError] = useCache(_childrenKeyFor(id), staleAfter, [])
  let [children, childrenStale, childrenError] = useCacheMap(ids, staleAfter, _resourceKeyFor, _addResourceHelpers)

  let stale = useMemo(() => idsStale || childrenStale, [idsStale, childrenStale])
  let error = useMemo(() => idsError ?? childrenError, [idsError, childrenError])

  // reload when stale
  useEffect(() => {
    id && stale && _resourceQueue.set(id, true)
  }, [id, stale, /*immutable: */ _resourceQueue])

  return [error ? {} : children, stale, error]
}


export function useResourcePermissions(id, staleAfter=FOLDER_LIFETIME) {
  let { useCache } = useContext(CacheContext)
  let { _permissionsKeyFor, _permissionsQueue } = useContext(ResourceContext)
  let result = useCache(_permissionsKeyFor(id), staleAfter, {})

  // reload when stale
  let isStale = result[1]
  useEffect(() => {
    id && isStale && _permissionsQueue.set(id, false) // pagination is always followed on this queue
  } , [id, isStale, /*immutable: */ _permissionsQueue])

  return result
}


export const ResourceContext = createContext()
export default function ResourceProvider({ children }) {
  //console.log('resource refresh')
  let { config, useApiEffect, iter } = useContext(ApiContext)
  let cache = useContext(CacheContext)
  let { user } = useContext(SessionContext)

  // resource helpers; most of these depend on the current config and should be
  // refreshed whenever the config changes (useCache does this by adding this
  // function as a dependency)
  let _addResourceHelpers = useCallback(resource => patchObject(resource, {
    has() {
      let expected = config.permissions.resource(...arguments)
      return (this.mask & expected) == expected
    }
  }, {
    type() {
      let {mime} = this
      if(mime == config.mimes.folder) return 'folder'

      // svgs are rendered separately so they have priority over images even if
      // they have (suggested) dimensions; we check for aspect because unless
      // the server identified the file as a SVG, we don't want to embed it in
      // the DOM (currently this is not relevant because the upload mimetype
      // will be octet-stream, but we may )
      if(this.aspect && mime.startsWith('image/svg')) return 'svg'

      // identified files
      if(this.video) return 'video'
      if(this.audio) return 'audio'
      if(this.width && this.height) return 'image'

      // guess based on filename; for security reasons, this should be togglable
      // behind a local flag for security-conscious users
      const name = this.name?.toLowerCase() ?? ''
      if(mime.endsWith(config.mimes.pending) && (
        name.endsWith('.jpg') ||
        name.endsWith('.png') ||
        name.endsWith('.jpeg') ||
        name.endsWith('.webp') ||
        name.endsWith('.gif') ||
        name.endsWith('.avif')
      ))
        return 'image'

      return 'unknown'
    }
  }), [config])

  // resource cache management; the `shared` pseudo-folder differs depending on
  // what team the current user is logged in as, and is thus cached separately;
  // while it's technically possible for the resources themselves to also differ
  // slightly across teams, this is exceptionally rare as resources may only be
  // shared within the same team; known exceptions are the public assets which
  // regular users only have read access to but staff members can also mutate;
  // to handle this, we flush the cache whenever isStaff changes (SessionContext)
  let _resourceKeyFor = useCallback(resource => {
    let id = resource?.id ?? resource
    return id == 'shared' ? 'shared-' + user.id : id
  }, [user?.id])

  let _childrenKeyFor = useCallback(resource => {
    let id = _resourceKeyFor(resource)
    return id ? `${id}-children` : null
  }, [_resourceKeyFor])

  let cacheResource = useCallback((resource, addToParent=true) => {
    // adds `resource` to the cache, persists it to disc if possible and
    // notifies all interested parties of the new value; `addToParent` should
    // almost always be true unless you're planning on doing it manually (e.g.
    // the local queue worker does this to reduce overall code complexity)
    if(typeof resource.hierarchy[0] == 'object') {
      // for display purposes we don't really care about permissions inherited
      // from unreadable ancestors, so we remove them if that's the case; once
      // we're done, the hierarchy only contains the ids and not the masks
      let {id, hierarchy} = resource
      hierarchy = hierarchy.map(({id}) => id)
      while(hierarchy.length && !hierarchy[0])
        hierarchy.shift()

      if(
        id != user.home && // not the home folder
        id != 'shared' && // not the shared folder
        !hierarchy.some(id => id == user.home) // does not belong to home
      ) {
        // resource is shared with the current user; as such, it is adopted by
        // the `shared` folder which itself is adopted by the user's home folder
        hierarchy.unshift(user.home, 'shared')
        if(!resource.folder)
          resource.folder = 'shared'
      }
      resource.hierarchy = hierarchy
    }

    cache.store(_resourceKeyFor(resource), prev => {
      // if moved, remove resource from parent folder's children; this doesn't
      // happen too often so it's fine that it's a bit slow
      if(prev && prev.folder != resource.folder)
        cache.update(
          _childrenKeyFor(prev.folder),
          prev => prev ? prev.filter(id => id != resource.id) : prev
        )

      // add resource to new parent folder's cache; this is somewhat slow so
      // it's skipped (done in bulk) when we're fetching a page of children
      if(addToParent && (!prev || prev.folder != resource.folder))
        cache.update(_childrenKeyFor(resource.folder),
          prev =>  prev ? [...new Set(prev).add(resource.id)] : [resource.id]
        )

      // update metadata but keep old url if not nearing expiration time and not
      // modified to prevent unnecessary browser url cache busts; folders are
      // frequently modified, but their url is only used to download a zip on
      // demand, so flushing that cache is not a problem; we force a cache bust
      // whenever the url is more than 75% expired (in practice with the current
      // config, this means that the url is still valid for another 6 hours)
      let now = Date.now()
      return (
        prev && // if there's a cached version available
        prev.urlExpires > now && // whose url is not close to expiration
        prev.modified?.on == resource.modified?.on // and the underlying resource was not modified
      )
        ? {...resource, url: prev.url, urlExpires: prev.urlExpires} // return cached url else
        : {...resource, urlExpires: now + config.api.read_url_lifetime * 750} // return new url
    })
  }, [config.api.read_url_lifetime, user?.home, _resourceKeyFor, _childrenKeyFor,
      /*immutable: */ cache])

  let flushResource = useCallback((target, parent) => {
    // removes `target` from the cache (can be an id or a resource object) and
    // also removes it from its `parent` folder's children list (if known)
    if(!parent && target.folder)
      parent = target.folder

    cache.store(_resourceKeyFor(target), null)
    cache.store(_childrenKeyFor(target), null)

    if(parent)
      cache.update(_childrenKeyFor(parent), prev =>
        prev ? prev.filter(id => id != target) : prev
      )
  }, [_resourceKeyFor, /*immutable: */ cache, _childrenKeyFor])

  // resource loader; we use an ordered map whose keys are the ids of the
  // resources that we should fetch and whose values are either false if we're
  // only interested in the resource, or true if we're interested in both the
  // resource and its children; this value can asynchronously change in between
  // the time a fetch is started and completed (e.g. if a parent component is
  // only interested in the resource, but a subcomponent also wants its children
  // in order to avoid an extra request, we check this value after every page
  // fetch; additionally, whenever we encounter an enqueued child that did not
  // request children (e.g. grandchildren), we remove it from the queue as it
  // should now be fresh
  let _resourceQueue = useMemo(() => new TaskQueue(), [])
  useEffect(() => () => _resourceQueue.clear(), [user?.id, _resourceQueue])
  useApiEffect(() => async api => {
    let id
    // eslint-disable-next-line no-cond-assign
    while(id = await _resourceQueue.next()) {
      let key = _resourceKeyFor(id)
      let childrenKey = _childrenKeyFor(id)
      try {
        console.log(`fetching ${id}`)

        // by convention, the user's home folder always includes a pseudo-folder
        // with id 'shared' that contains all the files and folders that were
        // shared with them; this folder's contents depends on the user's
        // current team and is cached under a namespace
        let shared = _addResourceHelpers({
          id: 'shared',
          name: 'Shared with me',
          folder: user.home,
          hierarchy: [user.home],
          created: null,
          modified: null,
          usage: {
            node: 0,
            size: 0,
          },
          limits: {
            node: 0,
            size: 0,
          },
          mime: config.mimes.folder,
          url: null,
          mask: config.permissions.resource('read', 'list'),
        })

        let old = cache.get(childrenKey)[0] ?? []
        let temp = new Set(old instanceof Error ? [] : old)
        let final = new Set()
        if(id == user.home) {
          cacheResource(shared)
          temp.add('shared')
          final.add('shared')
        }

        // NOTE: can't use iter here because the output format for non-shared
        // resources differs from the standard pagination format; this is also
        // part of the reason for resource fetching being quite complicated but
        // an API freeze was instituted some time ago
        let cursor = ''
        do {
          let resource = await api('GET', `files/${id}/?cursor=${cursor}`)
          let page = resource.children
          delete resource.children

          // shared folders have a slightly different api signature
          if(id == 'shared') {
            page = resource
            resource = shared
          }
          cacheResource(resource)

          for(let child of page.results) {
            if(id != 'shared') {
              // shared resources include their own hierarchy, but it is omitted
              // from the children of regular folders for brevity, so we have to
              // reconstruct it here; since we've already cached the resource
              // previously and cacheResource will mutate its hierarchy, it's
              // it's consistent to only append the id here
              child.hierarchy = [...resource.hierarchy, id]
              child.folder = id
            }

            // cache child resource and add to id list
            cacheResource(child, false)
            temp.add(child.id)
            final.add(child.id)

            // if this child was enqueued but without children, it can be safely
            // removed from the queue as it should now be fresh; subscribers can
            // override this and re-enqueue if they want an even fresher version
            if(!_resourceQueue.get(child.id))
              _resourceQueue.delete(child.id)
          }

          // navigate to next page
          cursor = page.next == cursor ? null : page.next
          if(id != 'shared' && page.results.length < config.api.page_size) {
            // this was the last page; avoid an extra request; note that for the
            // shared with me folder, the above assertion does not hold so we
            // have to load the likely empty next page as well to be sure
            cursor = null
            break
          }

          // trigger an inconsistent update on children and follow pagination if
          // some caller is interested in the children
          if(_resourceQueue.get(id))
            cache.notify(childrenKey, [...temp])
          else
            break
        } while(cursor)

        // if we've followed all the pages, trigger a consistent children update
        // regardless of whether anyone is listening; the data should be cached
        if(!cursor)
          cache.store(childrenKey, [...final])
        _resourceQueue.delete(id)
      } catch(err) {
        if(err instanceof DOMException && err.name == 'AbortError')
          return _resourceQueue.notify(id) // config or user change, re-enqueue

        // notify error and if we were following pagination notify that too
        cache.error(key, err)
        if(_resourceQueue.get(id))
          cache.error(childrenKey, err)
        _resourceQueue.delete(id)
      }
    }
  }, [config, user?.home, _resourceKeyFor, _childrenKeyFor, _addResourceHelpers,
      /*immutable*/ _resourceQueue, cache, cacheResource, flushResource])

  // similar situation for permissions; we use a different queue and there are
  // fewer interdependencies so there's no need to distinguish between with
  // children and without since they're always included; also, the shared folder
  // is not shareable by the current user and thus always has the same (== none)
  // permissions on it, so there's no need to namespace it like we do above
  let _permissionsKeyFor = useCallback(resource => {
    let id = resource?.id ?? resource
    return id ? `${id}-permissions` : null
  }, [])

  let _permissionsQueue = useMemo(() => new TaskQueue(), [])
  useEffect(() => () => _permissionsQueue.clear(), [user?.id, _permissionsQueue])
  useApiEffect(() => async api => {
    // TODO: since permissions are completely independent from one another,
    // multiple instances can run in parallel without affecting efficiency
    let id
    // eslint-disable-next-line no-cond-assign
    while(id = await _permissionsQueue.next()) {
      let key = _permissionsKeyFor(id)
      try {
        console.log(`fetching permissions for ${id}`)
        let temp = cache.get(key)[0] ?? {}
        if(temp instanceof Error)
          temp = {}
        let final = {}
        await iter(api, `files/${id}/permissions/`, page => {
          for(let entry of page)
            final[entry.member] = temp[entry.member] = {mask: entry.mask, path: entry.path}
          cache.notify(key, temp) // trigger an inconsistent update
        })
        cache.store(key, final) // trigger final persistent update
        _permissionsQueue.delete(id) // remove from queue
      } catch(err) {
        if(err instanceof DOMException && err.name == 'AbortError')
          return _permissionsQueue.notify(id) // config or user change, re-enqueue

        // notify error and remove from queue
        cache.error(key, err)
        _permissionsQueue.delete(id)
      }
    }
  }, [config.api.page_size, _permissionsKeyFor, /*immutable*/ _permissionsQueue, cache, iter])

  let flushResourcePermissions = useCallback(resource => {
    cache.store(_permissionsKeyFor(resource), null)
  }, [/*immutable*/ _permissionsKeyFor, cache])

  let value = useMemo(() => ({
    // hook helpers
    _addResourceHelpers,
    _resourceKeyFor,
    _childrenKeyFor,
    _permissionsKeyFor,
    _resourceQueue,
    _permissionsQueue,

    // public api
    useResource,
    useResourceChildren,
    useResourcePermissions,
    cacheResource,
    flushResource,
    flushResourcePermissions,
  }), [cacheResource, flushResource, _resourceKeyFor, _childrenKeyFor, _addResourceHelpers,
       /*immutable: */
       flushResourcePermissions, _permissionsKeyFor, _resourceQueue, _permissionsQueue])

  return <ResourceContext.Provider value={value}>{children}</ResourceContext.Provider>
}
