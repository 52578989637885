import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { AccountContext, ApiContext, AppContext, DeviceContext, MemberContext, TeamContext } from 'contexts'
import { SESSION_LIFETIME } from 'constants'


export const SessionContext = createContext()
export default function SessionProvider({ children }) {
  /* the session context is the second step in the login phase; it is responsible
  with binding the current session to a team and will render the team picker page
  if unable to do so; a team is selected if the account only belongs to a single
  team, or if sent via url params (e.g.email links)

  TODO: the server can selectively provide a team hint if it cannot confirm some
  resource's existence due to the current session being bound to a different
  team, but the resource in question is accessible by the account if bound to a
  different team; unfortuantely, due to tab syncing, this will break many things */
  //console.log('session refresh')
  let { session, cacheSession, flushSession, isPublic } = useContext(AccountContext)
  let { useApiEffect } = useContext(ApiContext)
  let { LoadingWrapper, showSnack } = useContext(AppContext)
  let { _addMemberHelpers } = useContext(MemberContext)
  let { useQueryParam } = useContext(DeviceContext)
  let { useTeams } = useContext(TeamContext)

  let [teams, stale] = useTeams(SESSION_LIFETIME, [])

  // log in to selected team
  let [selectedTeam, selectTeam] = useState(null)
  useApiEffect(() => selectedTeam && (async api => {
    try {
      cacheSession(await api('PUT', 'account/team/', {team: selectedTeam.id}))
      selectTeam(null)
    } catch(err) {
      if(err instanceof DOMException && err.name == 'AbortError') return

      selectTeam(null)
      let snack = {
        title: 'Could not sign in',
        status: 'error'
      }
      if(err.code == 'team.disabled') {
        snack.message = `The team you are trying to sign into is disabled and
                         may not be used at this time.`
        if(session?.team == selectedTeam.id)
          return flushSession()
      }
      else if(err.code == 'limit.device')
        snack.message = `The team you are trying to sign into has too many
                         active devices and may not be used at this time.`
      else if(err.code == 'session.expired' || err.code == 'authorization.invalid')
        return flushSession()
      else
        console.error(err)
      showSnack(snack)
    }
  }), [session, selectedTeam, /*immutable: */ cacheSession, flushSession, showSnack])

  // if there's only one team, or if there are multiple but one was chosen as
  // a query parameter and is not disabled, attempt to automatically log into it
  // if possible; note that this happens regardless of whether the current
  // session has a team or not, the only difference is that the app will start
  // rendering faster if the session is already bound to a team
  let team = useQueryParam('team')
  useEffect(() => {
    if(team && teams[team])
      return selectTeam(teams[team])
    let [first, ...rest] = Object.values(teams)
    if(first && !rest.length)
      selectTeam(first)
  }, [teams, /*immutable: */ team])

  let value = useMemo(() => ({
    token: session?.token,
    session: session?.session,
    account: session?.account,
    team: session?.team,
    user: _addMemberHelpers(session?.persona),
    cacheSession,
    flushSession,
  }), [session, _addMemberHelpers, /*immutable: */ cacheSession, flushSession])

  // only render children if logged in or bound to a team; otherwise display a
  // team picker
  // TODO: add actions
  let numActive = useMemo(() => Object.values(teams).reduce(
    (acc, team) => acc + Number(!team.has('disabled'))
  , 0), [teams])
  return <SessionContext.Provider value={value}>
    {session?.team || isPublic ? children
    : <LoadingWrapper>
        {stale || selectedTeam ? 'Fetching your files'
        : <>
          <p>Welcome back, {session.account.first_name} {session.account.last_name}!</p>
          {teams.length && <>
            To continue, please select your team:
            <ul>
              {teams.map(team =>
                <li
                  key={team.id}
                  onClick={selectTeam(team.id)}
                >{team.name}
                  {team.has('disabled') ? '(team inactive)' :
                  !team.has('confirmed') ? '(invitation pending)' : ''}
                </li>
              )}
            </ul>
          </>}
          {!stale && numActive == 0 && <>
            <p>It appears that you are no longer a member of any active teams.
            To continue using Portal, you must have an active subscription. If
            you believe that you have received this message in error, or if you
            would like to renew your subscription, do not hesitate to contact
            us.</p>
            <p>If you do not wish to be involved with Portal any longer, you
            may also <strong>permanently</strong> delete your account.</p>
          </>}
        </>}
      </LoadingWrapper>
    }
  </SessionContext.Provider>
}
