import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import LoadingButton from '@mui/lab/LoadingButton'

import styles from './Modal.module.scss'


export default function Modal({
  open,
  title,
  children,
  handleClose,
  primaryFooterAction,
  fullWidth = false,
  maxWidth = 'md',
  secondaryFooterAction,
}) {
  const closeAction = secondaryFooterAction ?? (handleClose
    ? { content: 'Close', onAction: handleClose }
    : null)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={styles.modal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle className={styles.modalHeader}>{title}</DialogTitle>

      <DialogContent className={styles.modalContent}>{children}</DialogContent>
      {closeAction || primaryFooterAction ? (
        <DialogActions className={styles.dialogActions}>
          <div className={styles.modalFooterActions}>
            {closeAction ? (
              <Button
                className={styles.modalAction}
                onClick={closeAction.onAction}
                color='secondary'
                variant='outlined'
                disabled={closeAction.disabled}
              >
                {closeAction.content}
              </Button>
            ) : null}

            {primaryFooterAction ? (
              <LoadingButton
                className={styles.modalAction}
                onClick={primaryFooterAction.onAction}
                color='primary'
                variant='contained'
                loading={primaryFooterAction.loading}
                disabled={primaryFooterAction.disabled}
              >
                {primaryFooterAction.content}
              </LoadingButton>
            ) : null}
          </div>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
