import Grid from '@mui/material/Grid'
import MediaBrowserGridItem from './MediaBrowserGridItem'

import styles from './MediaBrowserGrid.module.scss'


export default function MediaBrowserGrid({
  // navigation
  contents,
  onNavigate,
  onDownload,

  // mutators
  onShare,
  onCopy,
  onRename,
  onMove,
  onDelete,
}) {
  return (
    <div className={styles.container}>
      <Grid container spacing={3}>
        {contents.map(resource =>
          <Grid key={resource.id} item xs={6} sm={6} md={4} lg={3} xl={2}>
            <MediaBrowserGridItem
              resource={resource}
              onNavigate={onNavigate ? () => onNavigate(resource) : null}
              onDownload={onDownload ? () => onDownload(resource) : null}

              onShare={onShare ? () => onShare(resource) : null}
              onCopy={onCopy ? () => onCopy(resource) : null}
              onRename={onRename ? () => onRename(resource): null}
              onMove={onMove ? () => onMove(resource): null}
              onDelete={onDelete ? () => onDelete(resource) : null}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
