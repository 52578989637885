import { createContext, useCallback, useContext, useEffect, useRef, useMemo, useState } from 'react'

import { AppContext, CacheContext } from 'contexts'
import { API_URL, API_CLOCK_DRIFT, SHA256_WASM_PATH, CONFIG_LIFETIME } from 'constants'

import AuthorizationModal from 'components/authentication/AuthorizationModal'


async function sign(challenge, body) {
  /* returns a signature of 'body' by padding 'challenge' with random bytes
  until the first dword of sha256(challenge + candidate + sha256(body)) is
  less than or equal to the first dword of challenge (returned by the server)
  while the candidate number is in native endianess, the difficulty (start
  of challenge) and hash are interpreted in network order (big endian)
  both challenge and signature are 7 bit safe (using native browser base64)
  this operation is somewhat time-sensitive as the challenge also includes an
  expiration timestamp (and a hmac), though the limits are large enough to
  only disable rainbow tables and are thus mostly not a client-side concern */
  // input checks
  if(typeof challenge != 'string')
    throw new TypeError('challenge must be a string')
  if(challenge.length != 32) throw new RangeError('invalid challenge')

  if(body instanceof Blob) body = await body.arrayBuffer()
  if(!(body instanceof ArrayBuffer || ArrayBuffer.isView(body)))
    throw new TypeError('body must be binary')

  // convert `challenge` (base64) and hash `body` to binary
  let prefix = Uint8Array.from(atob(challenge), (c) => c.charCodeAt(0))
  let suffix = new Uint8Array(await crypto.subtle.digest('SHA-256', body))

  // payload = prefix + candidate + 2 pad bytes + suffix
  // TODO: use padding bytes if no solution is found
  let payload = new Uint8Array(prefix.length + 6 + suffix.length)
  payload.set(prefix, 0)
  payload.set(suffix, prefix.length + 6)

  // busy loop
  let difficulty = new DataView(payload.buffer).getUint32(0)
  let candidate = new Uint32Array(payload.buffer, prefix.length, 1)

  try {
    // use wasm if available
    /*! credits go to https://gist.github.com/bellbind/9b73b3965156f167cc0e500cdefb8c8a */
    sign.module = sign.module || await WebAssembly.compileStreaming(fetch(SHA256_WASM_PATH))
    let { reset, memory, update, final, offset } = (await WebAssembly.instantiate(sign.module)).exports
    new Uint8Array(memory.buffer).set(payload, offset)
    let len = payload.length
    let local = new Uint32Array(memory.buffer, offset + prefix.length)
    let hash = new DataView(memory.buffer)
    while (difficulty < (reset(), update(offset, len), final(), hash.getUint32(0)))
      local[0]++;
    candidate[0] = local[0]; // update source memory
  } catch (e) {
    // fall back on native browser implementation
    while(difficulty < new DataView(await crypto.subtle.digest('SHA-256', payload)).getUint32(0))
      candidate[0]++;
  }

  return btoa(String.fromCharCode(...payload.slice(0, 30)))
}


// monkeypatch abort controller if not supported; we can't abort outgoing api
// calls, but we can prevent future `setState` and `fetch` calls which is still
// important in a useEffect hook
let ApiAbortController = typeof AbortController != 'undefined' ? AbortController : class AbortController {
  constructor() {
    this.signal = {aborted: false}
  }
  abort(reason='aborted') {
    this.signal.reason = reason
    this.signal.aborted = true
  }
}


function useApiEffect(governor, deps) {
  /* provides a useEffect-compatible interface for running async api calls on a
  dependency array while allowing caching

  the effect hook (a.k.a. governor) is called on all dependency updates and is
  responsible with providing a task `handler`, which is an async function that
  receives the `api` parameter that may be used to perform api calls; if the
  governor returns a new handler, the previous handler (if any) will be aborted
  with a DOMError(name='AbortError') exception

  if the governor does not return a handler, the previous one (if any) will be
  allowed to complete its async task

  any existing task handler for this effect is aborted on component unmount */
  let controller = useRef(null)
  // abort outgoing request on unmount
  useEffect(() => () => controller.current && controller.current.abort(), [])

  let {api} = useContext(ApiContext)
  deps && deps.push(api)
  useEffect(() => {
    let handler = governor()
    if(typeof handler != 'function')
      return // keep old handler

    controller.current?.abort() // abort old handler
    handler(api.bind(controller.current = new ApiAbortController())) // start new
    .catch(err => {
      if(err instanceof DOMException && err.name == 'AbortError')
        console.warn('Unhandled API effect interruption')
      else if(err.code)
        console.warn(`Unhandled API error: ${err.code}`)
      else {
        console.error(`Error in API effect`)
        console.error('Request:')
        console.error(err.req)
        if(err.res) {
          console.error('Response:')
          console.error(err.res)
        }
        console.error('----------')
      }
      console.error(err)
    })
  // I solemnly swear that I am up to no good
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}


let maskJoiner = spec => function() {
  // converts (known) permission names into a binary mask
  let mask = 0
  for(let name of arguments)
    mask |= spec[name]|0
  return mask
}


export const ApiContext = createContext()
export default function ApiProvider({ children }) {
  //console.log('api refresh')
  /* we use two api contexts: the primary provider is used by the other global
  contexts to provide data access, the secondary provider is used by the
  components and will additionally check for stale token errors; should one be
  encountered, that request will be repeated after a password modal is shown to
  refresh the token; should the modal be closed, the authorized requests will
  throw the initial error */
  let derived = !!useContext(ApiContext)
  let { LoadingWrapper, showSnack } = useContext(AppContext)
  let cache = useContext(CacheContext)
  let { useCache } = cache

  // if the session is stale, block and repeat requests until re-authorized;
  // this only happens on derived api contexts (i.e. not toplevel)
  let [pendingAuthorization, setPendingAuthorization] = useState([])

  // api call
  let api = useCallback(async function(method, path, body=null, options) {
    // abort signal
    let signal
    if(options?.signal)
      signal = options.signal
    else if(this?.signal)
      signal = this.signal

    // request builder
    const headers = new Headers()
    let [token] = cache.get('token')
    if(token)
      headers.append('Authorization', `Bearer ${token}`)
    let init = {method, headers, signal,
      mode: 'cors',
      cache: 'no-store',
      redirect: 'error',
    }
    if(body) {
      headers.append('Content-Type', 'application/json')
      init.body = new TextEncoder().encode(JSON.stringify(body))
      if(options?.sign) {
        let [challenge] = cache.get('challenge')
        if(!challenge || new Date(challenge.expires).getTime() + API_CLOCK_DRIFT < Date.now())
          cache.store('challenge', challenge = await api('GET', 'account/challenge/'))
        headers.append('X-Proof-Of-Work', await sign(challenge.challenge, init.body))
      }
    }

    // check if already aborted
    if(signal?.aborted)
      throw new DOMException(signal.reason, 'AbortError')

    // send request
    if(!path.startsWith(API_URL))
      path = API_URL + path
    let req = new Request(path, init), res
    try {
      res = await fetch(req)

      // abort on api error
      let json = res.status == 204 ? null : await res.json()
      if(!res.ok) {
        let err = new Error(json.message)
        err.code = json.code
        throw err
      }
      return json
    } catch(err) {
      if(err.code == 'session.expired' || err.code == 'session.unbound') {
        // out of sync; force re-authenticate
        return cache.store('token', null)
      }
      if(derived && err.code == 'session.stale') {
        await new Promise((res, rej) => setPendingAuthorization(prev => [...prev, [res, () => rej(err)]]))
        return api(method, path, body, options)
      }
      err.req = req
      err.res = res
      throw err
    } finally {
      if(signal?.aborted) {
        // eslint-disable-next-line no-unsafe-finally
        throw new DOMException(signal.reason, 'AbortError')
      }
    }
  }, [/*immutable: */cache, derived])

  let configMapper = useCallback(config => config && Object.assign({}, {
    mimes: {
      folder: 'multipart/x-folder',
      pending: ';pending=1',
    },
    api: {
      page_size: 500,
      read_url_lifetime: 24 * 3600
    },
    // TODO: remove the above monkeypatches once they are exported by the api
    ...config,
    permissions: {
      member: maskJoiner(config.permissions.member),
      resource: maskJoiner(config.permissions.resource),
    },
  }), [])
  let [config, stale] = useCache('config', CONFIG_LIFETIME, null, configMapper)

  // if this is the master api context, refresh config when stale
  useEffect(() => void(!derived && stale &&
    api('GET', '.well-known/')
    .then(config => cache.store('config', config))
    .catch(err => {
      console.error(err)
      showSnack({
          title: 'Network error',
          status: 'error'
        })
    })
  ), [stale, derived, /*immutable: */ api, showSnack, cache])

  let iter = useCallback(async (api, path, options, cb=null) => {
    /* iterates through GET `path` and returns the results as an ordered list
    if `cb` is defined, it is asynchronously triggered whenever a new page is
    loaded and returns `undefined`; if `cb` returns a non-undefined value for
    any given page, the iteration is stopped prematurely and `iter` returns that
    value instead */
    if(typeof options == 'function') {
      cb = options
      options = {}
    }
    let results = []
    let cursor = ''
    path += ~path.indexOf('?') ? '&' : '?'
    do {
      let page = await api('GET', `${path}cursor=${cursor}`, null, options)
      if(cb) {
        let result = await cb(page['results'])
        if(typeof result != 'undefined')
          return result
      } else
        for(let entry of page['results'])
          results.push(entry)
      cursor = (page['next'] == cursor) || (page['results'].length < config.api.page_size) ? '' : page['next']
    } while(cursor)
    if(!cb) return results
  }, [config?.api.page_size])

  let value = useMemo(() => ({config, api, iter, useApiEffect}), [config, iter, /*immutable: */ api])

  return <ApiContext.Provider value={value}>
    {config ? children : <LoadingWrapper>Configuring application</LoadingWrapper>}
    {!!pendingAuthorization.length &&
      <AuthorizationModal
        onSuccess={() => setPendingAuthorization(prev => (prev.forEach(promise => promise[0]()), []))}
        onClose={() => setPendingAuthorization(prev => (prev.forEach(promise => promise[1]()), []))}
      />
    }
  </ApiContext.Provider>
}
