import { useContext, useEffect } from 'react'
import { AppContext } from 'contexts'

import Box from '@mui/material/Box'
import Image from 'next/image'

import styles from './AuthWrapper.module.scss'


export default function AuthWrapper({ children }) {
  // if this is rendered, we're not logged in; use this as the default wrapper
  // for future loading screens
  let { setLoadingWrapper }  = useContext(AppContext)
  useEffect(() => setLoadingWrapper(() => AuthWrapper), [/*immutable: */ setLoadingWrapper])

  return (
    <div className={styles.container}>
      <Box className={styles.image}>
        <Image
          src='/images/login-image.jpg'
          alt='Padcaster promotional image'
          loader={x => x.src}
          layout='fill'
          objectFit='cover'
        />
      </Box>
      <Box className={styles.content}>
        <div className={styles.form}>
          <div className={styles.logo}>
            <Image
              src='/images/logo-black.svg'
              alt='Padcaster logo'
              loader={x => x.src}
              width={480}
              height={86}
            />
            <p className={styles.tagline}>
              Cloud-based asset management to make your video workflow faster
              and easier.
            </p>
          </div>
          {children}
        </div>
      </Box>
    </div>
  )
}
