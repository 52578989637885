import { useContext, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { ResourceContext } from 'contexts'

import PlayIcon from 'components/icons/PlayIcon'
import ReactPlayer from 'react-player'
import Svg from 'components/common/Svg'

import styles from './MediaPreview.module.scss'


const IMAGE_MIME_PRIORITY = new Map([
  // maps mimetypes to their display priority; higher is better, 0 is unknown
  ['image/jpeg', 1],

  // transparent images score higher
  ['image/gif', 2], // 256 color palette
  ['image/png', 3], // can be true color

  // vectors score even higher
  ['image/svg+xml', 4]
])
const TRANSCODABLE = new Set(['image', 'video', 'audio'])


function FilePreview({ resource, canvas, interactive }) {
  let { useResourceChildren } = useContext(ResourceContext)
  let [children] = useResourceChildren(TRANSCODABLE.has(resource.type) ? resource.id : null)

  let thumbnail = useMemo(() => {
    // first filter the resource's children to only consider images
    let candidates = Object.values(children).filter(child => child.type == 'image' || child.type == 'svg')

    // if the current file is an image, include it as a candidate as well; we
    // don't need to handle vectors here because they're embedded directly,
    // without listing their children
    if(resource.type == 'image')
      candidates.push(resource)

    // filter candidates to those that are larger than the target size
    let larger = candidates.filter(({width, height, aspect}) => aspect || (width >= canvas.width && height >= canvas.height))
    if(!larger.length && candidates.length) {
      // if all candidates are smaller, use the largest one by surface area
      let max = candidates.reduce((best, image) =>
        image.width * image.height > best.width * best.height ? image : best
      )
      if(max)
        larger.push(max)
    }
    candidates = larger

    // filter candidates to those whose mimetype has the highest priority
    if(candidates.length) {
      let max = 0
      candidates = candidates.reduce((acc, image) => {
        let current = IMAGE_MIME_PRIORITY.get(image.mime, 0)
        if(current == max) {
          acc.push(image)
          return acc
        }
        max = current
        return [image]
      }, [])
    }

    // of whatever is left, use the one with the smallest size
    if(candidates.length)
      return candidates.reduce((best, image) => image.size < best ? image : best)

    // return a default image
    return {url: '/images/default-img.jpeg', type: 'image'}
  }, [canvas, resource, children])

  let url = useMemo(() => {
    let candidates = Object.values(children).filter(child => child.type == 'video')

    // use first video that seems playable
    // TODO: get best match for canvas size, similar to thumbnails
    for(let candidate of candidates)
      if(candidate.video && (
        candidate.video.codec == 'avc' || // transcoder output
        candidate.video.codec == 'h264' // storyblocks default
      ) && (!candidate.audio || candidate.audio.codec == 'aac'))
        return candidate.url

    // use first audio that seems playable
    for(let candidate of candidates)
      if(candidate.audio && candidate.audio.codec == 'aac')
        return candidate.url

    // otherwise the transcoder is probably still pending; return raw url and hope for the best
    return resource.url
  }, [resource, children])

  let width, height
  let aspect = resource.video
  ? resource.video.width  / resource.video.height
  : resource.width / resource.height
  if(canvas.width && aspect) {
    if(canvas.height) {
      width = Math.min(canvas.width, canvas.height * aspect)
      height = Math.min(canvas.height, canvas.width / aspect)
    } else {
      width = canvas.width
      height = canvas.width / aspect
    }
  } else
    width = height = '100%'

  let media = resource.type == 'video' || resource.type == 'audio'
  const [playing, setPlaying] = useState(false)

  return <div
    className={styles.image}
    style={interactive && media ? {
      cursor: 'pointer',
    } : {
      height: canvas.height == 0 ? height : '100%',
      backgroundImage: `url(${thumbnail.url})`,
      backgroundSize: thumbnail.type == 'svg' && resource.type == 'audio' ? '100% 100%' : 'cover'
    }}
    onClick={() => setPlaying(prev => !prev)}
  >
    {media && (interactive
    ? <ReactPlayer
      className={styles.player}
      light={thumbnail.url}
      url={url}
      playing={playing}
      controls={playing}
      width={width}
      height={height}
    />
    : <PlayIcon />)}
  </div>
}


function SvgPreview({ resource, canvas }) {
  let width, height
  if(canvas.width) {
    if(canvas.height) {
      width = Math.min(canvas.width, canvas.height * resource.aspect)
      height = Math.min(canvas.height, canvas.width / resource.aspect)
    } else {
      width = canvas.width
      height = canvas.width / resource.aspect
    }
  } else {
    height = 50
    width = height * resource.aspect
  }

  return <Svg className={styles.vector} url={resource.url} width={width} height={height} />
}


function FolderPreview({ resource }) {
  return <div className={styles.image} style={{
    backgroundImage: 'url(/images/folder-icon.svg)',
    backgroundSize: 'contain'
  }}>
    <h1 className={styles.name}>{resource.name}</h1>
  </div>
}


export default function MediaPreview({ resource, interactive=true }) {
  let container = useRef()

  let [canvas, setCanvas] = useState({width: 0, height: 0})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    let ref = container.current
    if(ref) setCanvas({
      width: ref.offsetWidth,
      height: ref.offsetHeight
    })
  }, [])

  return <div ref={container} className={styles.container}>
    {canvas.width && (resource.type == 'folder' ?
      <FolderPreview resource={resource} />
    : resource.type == 'svg' ?
      <SvgPreview resource={resource} canvas={canvas} />
    : <FilePreview resource={resource} canvas={canvas} interactive={interactive} />
    ) || null}
  </div>
}
