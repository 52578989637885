import { useContext, useMemo } from 'react'
import { ResourceContext } from 'contexts'

import AddFolderIcon from 'components/icons/AddFolderIcon'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import IconButton from '@mui/material/IconButton'
import UploadIcon from 'components/icons/UploadIcon'

import styles from './MediaBrowserModalHeader.module.scss'


export default function MediaBrowserModalHeader({
  folder,
  onNavigate,

  // mutators
  onCreate,
  onUpload,
}) {
  let { useResource } = useContext(ResourceContext)

  let [parent] = useResource(folder?.folder)
  let readOnly = useMemo(() => !folder || folder.type != 'folder' || !folder.has('write'), [folder])

  return (
    <div className={styles.header}>
      <div className={styles.backBtn} onClick={() => parent && onNavigate(parent)}>
        {parent && <ArrowBackIosIcon />}
        {folder ? folder.name ?? 'Home' : '...'}
      </div>
      {(onCreate || onUpload) &&
        <div className={styles.buttons}>
          {onCreate &&
            <IconButton
              onClick={onCreate}
              disabled={readOnly}
            >
              <AddFolderIcon color={readOnly ? '#ccc' : '#ff671b'} />
            </IconButton>
          }
          {onUpload &&
            <IconButton
              onClick={onUpload}
              disabled={readOnly}
            >
              <UploadIcon color={readOnly ? '#ccc' : '#ff671b'} />
            </IconButton>
          }
        </div>
      }
    </div>
  )
}
