import { useContext, useState } from 'react'
import { ApiContext, AccountContext, DeviceContext } from 'contexts'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Link from 'next/link'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'

import styles from './LoginForm.module.scss'


export default function LoginForm() {
  let { api } = useContext(ApiContext)
  let { cacheSession } = useContext(AccountContext)
  let { name, device } = useContext(DeviceContext)

  let [error, setError] = useState(null);
  let [loading, setLoading] = useState(false)

  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('')
  async function onSubmit(event) {
    event.preventDefault();
    setLoading(true)
    try {
      cacheSession(await api('POST', 'account/login/', {email, password, name, device}, {sign: true}))
    } catch(err) {
      if(err.code == 'credentials.invalid')
        setError('Email and Password do not match')
      else {
        console.error(err)
        setError(`Apologies for the inconvenience, but we are unable to sign
                  you in at this time due to technical difficulties. Please try
                  again later or contact us if the issue persists.`)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      {error ? <Alert severity='error'>{error}</Alert> : null}

      <FormControl required className={styles.formControl}>
        <label htmlFor='email'>Email</label>
        <TextField
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          id='email'
          name='email'
          type='email'
          variant='outlined'
        />
      </FormControl>
      <FormControl required className={styles.formControl}>
        <label htmlFor='password'>Password</label>
        <TextField
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          name='password'
          type='password'
          variant='outlined'
        />
      </FormControl>
      <p className={styles.forgotPassword}>
        <Link href='/auth/reset-password' passHref>
          <Button size='small' variant='text'>
            Forgot password?
          </Button>
        </Link>
      </p>

      <LoadingButton
        type='submit'
        disabled={!email || !password}
        loading={loading}
        variant='contained'
        size='large'
        className={styles.submitButton}
      >
        Sign In
      </LoadingButton>

      {/* <p className={styles.signUp}>
        Don&apos;t yet have an account? <Link href="/auth/signup" passHref><Button size="small" variant="text">Create one</Button></Link>
      </p> */}

      <p className={styles.tos}>
        Read our{' '}
        <a href='/terms' target='_blank' rel='noopener noreferrer'>
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a href='/privacy' target='_blank' rel='noopener noreferrer'>
          Privacy Policy
        </a>
      </p>
    </form>
  );
}
