import { createContext, useCallback, useContext, useRef, useState } from 'react'

import { ApiContext, AppContext, ResourceContext, UploadContext } from 'contexts'

import MediaBrowserModal from 'components/mediabrowser/media-browser-modal/MediaBrowserModal'
import RenameModal from 'components/mediabrowser/rename-modal/RenameModal'


export const FilesystemContext = createContext()
export default function FilesystemProvider({ children }) {
  /* this file used to be called MediaContext and was much larger but now most
  of its functionality has been moved to other contexts and the media page; it
  now only hosts filesystem specific functionality that is useful across
  sections, such as the location picker modal; this is also the place where the
  ux for resolving naming conflicts should sit; currently, the only conflict
  resolution method provided is the one used when uploading files, which will
  attempt to do anything it can in order to map already uploaded parts into a
  resource, including renaming and saving to different folders */
  //console.log('filesystem refresh')
  let { showSnack } = useContext(AppContext)
  let { api } = useContext(ApiContext)
  let { cacheResource, flushResource } = useContext(ResourceContext)
  let { getUniqueFilename, enqueueUpload } = useContext(UploadContext)

  // browsers don't reliably fire the onchange event for file inputs so we have
  // to use ref here to ensure that we open the dialog every time we should
  let fileInput = useRef()
  let fileForm = useRef()
  let uploadFilesIn = useCallback(async (folder, files, uniqueNames) => {
    // uploads `files` to `folder`, or opens a file picker and uploads its
    // selection if no files are provided; note that we use an additional ref to
    // persist the context that the file selection dialog was opened in until
    // the `onchange` handler is called; this might be relaxed in the future if
    // browsers can reliably handle the event listener change, but currently all
    // uploads are aborted if the user context is changed anyway and the engine
    // should be  blocked while the os-dialog is open, so this is probably fine
    if(!files) {
      fileForm.current.reset()
      fileInput.current.onchange = ({target}) => uploadFilesIn(folder, target.files)
      fileInput.current.click()
      return
    }

    files = new Set(files)
    for(let file of files)
      try {
        let filename = uniqueNames ? getUniqueFilename(file.name) : file.name
        await enqueueUpload(folder, filename, file)
        files.delete(file)
      } catch(err) {
        if(!err.code) err.code = 'fetch.network'

        // handled recursively
        if(err.code == 'conflict.name' && !uniqueNames)
          continue

        // permanent error
        files.delete(file)
        let snack = {
          title: 'Could not upload file',
          status: 'error'
        }

        if(err.code.startsWith('limit.'))
          snack.message = `You have used up all your available quota at this
                           location. Consider deleting or moving some files
                           before trying again.`
        else if(err.code == 'resource.invalid' || err.code.startsWith('permission.')) {
          snack.message = `It appears that you no longer have permission to
                           upload files at this location.`
          err.code == 'resource.invalid' && flushResource(parent)
        } else
          console.error(err)
        showSnack(snack)
        break
      }

    // TODO: take different actions depending on the conflict resolution method
    if(files.size)
      uploadFilesIn(folder, files, true)
  }, [enqueueUpload, flushResource, /*immutable: */ getUniqueFilename, showSnack])

  // dialogs
  let [namePickerProps, chooseName] = useState(null)
  let [mediaBrowserProps, browseMedia] = useState(null)

  // creates a new folder called `name` in `parent` or prompt for a name
  // this is defined here because it's used in the location picker modal
  let createFolderIn = useCallback((parent, name) => {
    let parentId = parent.id ?? parent
    async function onConfirm(name) {
      try {
        cacheResource(await api('POST', `files/${parentId}/`, {name}))
      } catch(err) {
        let snack = {
          title: 'Could not create folder',
          status: 'error'
        }

        if(err.code == 'conflict.name')
          snack.message = `Another resource with the same name already exists
                           in this folder.`
        else if(err.code.startsWith('limit.'))
          snack.message = `You have used up all your available quota at this
                           location. Consider deleting or moving some files or
                           folders before trying again.`
        else if(err.code == 'resource.invalid' || err.code?.startsWith('permission.')) {
          snack.message = `It appears that you no longer have permission to
                           create folders at this location or it was deleted.`
          err.code == 'resource.invalid' && flushResource(parent)
        } else
          console.error(err)
        showSnack(snack)
      }
    }

    if(name) return void(onConfirm(name))

    chooseName({
      title: 'Create a folder',
      placeholder: 'Folder name',
      action: 'Create',
      onConfirm,
    })
  }, [api, cacheResource, flushResource, /*immutable: */ chooseName, showSnack])

  return (
    <FilesystemContext.Provider
      value={{
        browseMedia,
        createFolderIn,
        uploadFilesIn,
        chooseName,
      }}
    >
      <form ref={fileForm}>
        <input style={{display: 'none'}} type='file' multiple ref={fileInput} />
      </form>

      {children}

      {mediaBrowserProps && <MediaBrowserModal {...mediaBrowserProps}
        onClose={() => browseMedia(null)}
        onCreate={createFolderIn}
        onUpload={uploadFilesIn}
      />}
      {namePickerProps && <RenameModal {...namePickerProps} onClose={() => chooseName(null)}/>}
    </FilesystemContext.Provider>
  )
}
