const TrashIcon = ({ width = 18, height = 19, color = '#979797' }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox='0 0 18 19'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Trash</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Icon/trash'
          transform='translate(1.000000, 1.000000)'
          fill={color}
          fillRule='nonzero'
        >
          <g id='Trash' transform='translate(-1.000000, 0.000000)'>
            <path
              d='M15.4906466,2.86306528 C16.0034824,2.86306528 16.4261538,3.24910547 16.4839189,3.74644416 L16.4906466,3.86306528 L16.4906466,16.8630653 C16.4906466,17.3759011 16.1046064,17.7985724 15.6072677,17.8563375 L15.4906466,17.8630653 L2.4906466,17.8630653 C1.97781076,17.8630653 1.55513944,17.4770251 1.49737433,16.9796864 L1.4906466,16.8630653 L1.4906466,3.86306528 C1.4906466,3.31078053 1.93836185,2.86306528 2.4906466,2.86306528 C3.00348244,2.86306528 3.42615376,3.24910547 3.48391886,3.74644416 L3.4906466,3.86306528 L3.49,15.863 L14.49,15.863 L14.4906466,3.86306528 C14.4906466,3.35022944 14.8766868,2.92755812 15.3740255,2.86979301 L15.4906466,2.86306528 Z'
              id='Path-5'
            ></path>
            <path
              d='M11.6631548,-1 C12.0164224,-1 12.3398862,-0.814049314 12.5191939,-0.516964968 L12.5794537,-0.400495076 L13.6851247,2.12918695 C13.9063121,2.63524487 13.6753788,3.22479361 13.1693209,3.44598094 C12.69941,3.65136917 12.1575095,3.46691874 11.9053977,3.03434249 L11.8525269,2.9301771 L11.008,1 L7.239,1 L6.59294768,2.85848987 C6.42432323,3.34281042 5.92077045,3.61504684 5.43209174,3.50607116 L5.31974299,3.47407887 C4.83542244,3.30545442 4.56318602,2.80190164 4.6721617,2.31322293 L4.70415399,2.20087418 L5.58490588,-0.328807848 C5.71083581,-0.690501859 6.03036282,-0.944812293 6.40323036,-0.99205744 L6.52930272,-1 L11.6631548,-1 Z'
              id='Path-7'
            ></path>
            <path
              d='M16.521845,2.34879057 C17.0741297,2.34879057 17.521845,2.79650582 17.521845,3.34879057 C17.521845,3.8616264 17.1358048,4.28429773 16.6384661,4.34206283 L16.521845,4.34879057 L1,4.34879057 C0.44771525,4.34879057 0,3.90107531 0,3.34879057 C0,2.83595473 0.38604019,2.4132834 0.883378875,2.3555183 L1,2.34879057 L16.521845,2.34879057 Z'
              id='Path-6'
            ></path>
            <path
              d='M11.1451428,6.46677973 C11.3906027,6.46677973 11.5947512,6.64365489 11.6370871,6.8769041 L11.6451428,6.96677973 L11.6451428,12.7723847 C11.6451428,13.0485271 11.4212852,13.2723847 11.1451428,13.2723847 C10.8996829,13.2723847 10.6955344,13.0955096 10.6531985,12.8622604 L10.6451428,12.7723847 L10.6451428,6.96677973 C10.6451428,6.69063736 10.8690004,6.46677973 11.1451428,6.46677973 Z'
              id='Path-8'
            ></path>
            <path
              d='M7.14514281,6.46677973 C7.3906027,6.46677973 7.59475118,6.64365489 7.63708714,6.8769041 L7.64514281,6.96677973 L7.64514281,12.7723846 C7.64514281,13.048527 7.42128519,13.2723846 7.14514281,13.2723846 C6.89968292,13.2723846 6.69553444,13.0955095 6.65319848,12.8622603 L6.64514281,12.7723846 L6.64514281,6.96677973 C6.64514281,6.69063736 6.86900044,6.46677973 7.14514281,6.46677973 Z'
              id='Path-8'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TrashIcon;
