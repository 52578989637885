import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { AccountContext, ApiContext, CacheContext } from 'contexts'
import { MEMBER_LIFETIME } from 'constants'
import { patchObject } from 'utils'


function useMember(id, staleAfter=MEMBER_LIFETIME) {
  let { useCache } = useContext(CacheContext)
  let { _refresh, _memberKeyFor, _addMemberHelpers } = useContext(MemberContext)
  let result = useCache(_memberKeyFor(id), staleAfter, null, _addMemberHelpers)

  // reload when stale
  let stale = result[1]
  useEffect(() => {
    stale && _refresh()
  }, [stale, /*immutable: */ _refresh])

  return result
}


function useMembers(staleAfter=MEMBER_LIFETIME) {
  let { useCache, useCacheMap } = useContext(CacheContext)
  let { _refresh, _teamKey, _memberKeyFor, _addMemberHelpers } = useContext(MemberContext)
  let [ids, idsStale, idsError] = useCache(_teamKey(), staleAfter, [])
  let [members, membersStale, membersError] = useCacheMap(ids, staleAfter, _memberKeyFor, _addMemberHelpers)

  let stale = useMemo(() => idsStale || membersStale, [idsStale, membersStale])
  let error = useMemo(() => idsError ?? membersError, [idsError, membersError])

  // reload when stale
  useEffect(() => void(stale && _refresh()), [stale, _refresh])

  return error ? [{}, false, error] : [members, stale, null]
}


export const MemberContext = createContext()
export default function MemberProvider({children}) {
  //console.log('member refresh')
  let { session } = useContext(AccountContext)
  let { config, useApiEffect, iter } = useContext(ApiContext)
  let cache = useContext(CacheContext)

  // member helpers; most of these depend on the current config and should be
  // refreshed whenever the config changes (useCache does this by adding this
  // function as a dependency to its result)
  let _addMemberHelpers = useCallback(member => patchObject(member, {
    has() {
      let expected = config.permissions.member(...arguments)
      return (this.mask & expected) == expected
    }
  }, {
    name() {
      return [this.first_name, this.last_name].join(' ').trim()
    },
    isStaff() {
      // this is a bit of a hack; since the app is always operating in the
      // context of a single team, all members are either staff or not depending
      // on whether the current user is a staff member; to add insult to injury,
      // the team is decided by the session context, which we can't import here
      // beause it sits below this data provider
      return session?.team == config.filesystem.admin
    },
    isAdmin() {
      return this.has('admin')
    }
  }), [config, session?.team])

  // member cache management; we need to namespace the list of members under
  // the current team to allow for fast user switching; unfortunately, we don't
  // have the team here because that's populated by the session context which
  // needs to import this
  let _teamKey = useCallback(() => session?.team + '-members', [session?.team])
  let _memberKeyFor = useCallback(member => member?.id ?? member, [])

  let { cacheMember, flushMember } = useMemo(() => ({
    cacheMember(member) {
      cache.store(_memberKeyFor(member), member)
      cache.update(_teamKey(), prev => prev ? [...new Set(prev).add(member.id)] : prev)
    },
    flushMember(member) {
      let target = member.id ?? member
      cache.store(_memberKeyFor(member), null)
      cache.update(_teamKey(), prev => prev ? prev.filter(id => id != target) : prev)
    }
  }), [_teamKey, _memberKeyFor, cache])

  // we don't need a queue here; members always come from the same endpoint
  // which is refreshed whenever stale via the api effect; the hooks determine
  // staleness and trigger _refresh while waiting for their key to load
  let [stale, setStale] = useState(false)
  let _refresh = useCallback(() => setStale(true), [])
  useApiEffect(() => stale && (async api => {
    console.log(`fetching members`)
    let key = _teamKey()
    try {
      let old = cache.get(key)[0] ?? []
      let temp = new Set(old instanceof Error ? [] : old)
      let final = new Set()

      await iter(api, 'team/members/', page => {
        for(let member of page) {
          cacheMember(member)
          temp.add(member.id)
          final.add(member.id)
        }
        cache.notify(key, [...temp]) // trigger an inconsistent update
      })
      cache.store(key, [...final]) // trigger final persistent update
      setStale(false)
    } catch(err) {
      if(err instanceof DOMException && err.name == 'AbortError') return

      // notify error
      cache.error(key, err)
    }
  }), [stale, _teamKey, cache, cacheMember, iter])

  let value = useMemo(() => ({
    // hook helpers
    _addMemberHelpers,
    _refresh,
    _teamKey,
    _memberKeyFor,

    // public api
    useMember,
    useMembers,
    cacheMember,
    flushMember,
  }), [_addMemberHelpers, _refresh, _teamKey, _memberKeyFor, cacheMember, flushMember])
  return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>
}
