import styles from './SignOutIcon.module.scss'


export default function SignOutIcon () {
  return (
    <div className={styles.container}>
      <svg width="14px" height="12px" viewBox="0 0 14 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Sign out</title>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Main/Sidebar" transform="translate(-18.000000, -693.000000)" className={styles.group} fillRule="nonzero">
            <g id="Group-7" transform="translate(18.000000, 678.500000)">
              <g id="Group-8" transform="translate(0.000000, 14.500000)">
                <g id="Sign-out" transform="translate(0.500000, 0.500000)">
                  <path d="M4.786484,0 C5.06262638,0 5.286484,0.223857625 5.286484,0.5 C5.286484,0.745459889 5.10960884,0.94960837 4.87635963,0.991944331 L4.786484,1 L1,1 L1,10 L4.786484,10 C5.03194389,10 5.23609237,10.1768752 5.27842833,10.4101244 L5.286484,10.5 C5.286484,10.7454599 5.10960884,10.9496084 4.87635963,10.9919443 L4.786484,11 L0.5,11 C0.254540111,11 0.0503916296,10.8231248 0.00805566941,10.5898756 L4.4408921e-16,10.5 L4.4408921e-16,0.5 C4.4408921e-16,0.254540111 0.176875161,0.0503916296 0.410124368,0.00805566941 L0.5,0 L4.786484,0 Z" id="Path-16"></path>
                  <path d="M10.1872116,2.40991873 C10.3787102,2.2563647 10.6486282,2.26664551 10.8275724,2.4221334 L10.8900813,2.48721156 L13.1009922,5.24445841 C13.2398216,5.41759385 13.2459835,5.65768246 13.1260728,5.83599924 L13.066983,5.90826348 L10.8560721,8.15101663 C10.6622109,8.34766981 10.3456365,8.34993321 10.1489834,8.15607208 C9.97418054,7.98375107 9.95296964,7.71447144 10.086569,7.51864312 L10.1439279,7.44898337 L12.042,5.523 L10.1099187,3.11278844 C9.9563647,2.92128983 9.96664551,2.65137182 10.1221334,2.47242758 L10.1872116,2.40991873 Z" id="Path-17"></path>
                  <path d="M11.7129192,5 C11.9890616,5 12.2129192,5.22385763 12.2129192,5.5 C12.2129192,5.74545989 12.0360441,5.94960837 11.8027949,5.99194433 L11.7129192,6 L5.5,6 C5.22385763,6 5,5.77614237 5,5.5 C5,5.25454011 5.17687516,5.05039163 5.41012437,5.00805567 L5.5,5 L11.7129192,5 Z" id="Path-18"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}
