import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { AccountContext, ApiContext, CacheContext } from 'contexts'
import { TEAM_LIFETIME } from 'constants'
import { patchObject } from 'utils'


function useTeam(id, staleAfter=TEAM_LIFETIME) {
  let { useCache } = useContext(CacheContext)
  let { _addTeamHelpers, _refresh, _teamKeyFor } = useContext(TeamContext)
  let result = useCache(_teamKeyFor(id), staleAfter, null, _addTeamHelpers)

  // reload when stale
  let stale = result[1]
  useEffect(() => {
    stale && _refresh()
  }, [stale, /*immutable: */ _refresh])

  return result
}


function useTeams(staleAfter=TEAM_LIFETIME) {
  let { useCache, useCacheMap } = useContext(CacheContext)
  let { _addTeamHelpers, _refresh, _teamsKey, _teamKeyFor } = useContext(TeamContext)
  let [ids, idsStale, idsError] = useCache(_teamsKey(), staleAfter, [])
  let [teams, teamsStale, teamsError] = useCacheMap(ids, staleAfter, _teamKeyFor, _addTeamHelpers)

  let stale = useMemo(() => idsStale || teamsStale, [idsStale, teamsStale])
  let error = useMemo(() => idsError ?? teamsError, [idsError, teamsError])

  // reload when stale
  useEffect(() => void(stale && _refresh()), [stale, _refresh])

  return error ? [{}, false, error] : [teams, stale, null]
}


export const TeamContext = createContext()
export default function TeamProvider({ children }) {
  let { config, useApiEffect, iter } = useContext(ApiContext)
  let cache = useContext(CacheContext)
  let { session } = useContext(AccountContext)

  // team helpers; identical to the member helper, except while that operates
  // on a team member's mask on the same team, this operates on this member's
  // mask on all the teams that they are a member of
  let _addTeamHelpers = useCallback(team => patchObject(team, {
    has() {
      let expected = config.permissions.member(...arguments)
      return (this.mask & expected) == expected
    }
  }, {}), [config.permissions])

  // because multiple accounts may be logged in (arguably this is only the case
  // for app testers), we have to namespace the teams to the account id
  let _teamsKey = useCallback(() => session ? (session.account.id + '-teams') : null, [session])
  let _teamKeyFor = useCallback(target => target.id ?? target, [])

  let {cacheTeam, flushTeam} = useMemo(() => ({
    cacheTeam(team) {
      cache.store(_teamKeyFor(team), team)
    },
    flushTeam(team) {
      cache.store(_teamKeyFor(team), null)
    }
  }), [cache, _teamKeyFor])

  // we don't need a queue here; members always come from the same endpoint
  // which is refreshed whenever stale via the api effect; the hooks determine
  // staleness and trigger _refresh while waiting for their key to load
  let [stale, setStale] = useState(false)
  let _refresh = useCallback(() => setStale(true), [])
  useApiEffect(() => stale && (async api => {
    console.log(`fetching teams`)
    let key = _teamsKey()
    try {
      let old = cache.get(key)[0] ?? []
      let temp = new Set(old instanceof Error ? [] : old)
      let final = new Set()

      await iter(api, 'account/teams/', page => {
        for(let team of page) {
          cacheTeam(team)
          temp.add(team.id)
          final.add(team.id)
        }
        cache.notify(key, [...temp]) // trigger an inconsistent update
      })
      cache.store(key, [...final]) // trigger final persistent update
      setStale(false)
    } catch(err) {
      if(err instanceof DOMException && err.name == 'AbortError') return

      // notify error
      cache.error(key, err)
    }
  }), [stale, cache, cacheTeam, /*immutable: */ iter, _teamsKey])

  let value = useMemo(() => ({
    // hook helpers
    _addTeamHelpers,
    _refresh,
    _teamsKey,
    _teamKeyFor,

    // public api
    useTeam,
    useTeams,
    cacheTeam,
    flushTeam,
  }), [_teamsKey, _addTeamHelpers, /*immutable: */ _refresh, _teamKeyFor, cacheTeam, flushTeam])

  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
}
