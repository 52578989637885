import { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { formatDate, formatFileSize } from 'utils'

import AudioIcon from 'components/icons/AudioIcon'
import FolderIcon from 'components/icons/FolderIcon'
import IconButton from '@mui/material/IconButton'
import ListMoreHoverIcon from 'components/icons/ListMoreHoverIcon'
import MediaImageIcon from 'components/icons/MediaImageIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import UnknownFileIcon from '@mui/icons-material/DoNotDisturbAltOutlined'
import VideoImageIcon from 'components/icons/VideoImageIcon'

import styles from './MediaBrowserListItem.module.scss'


export default function MediaBrowserListItem({
  resource,
  selection,
  onNavigate,
  onDownload,

  onToggleSelection,
  onShare,
  onCopy,
  onRename,
  onMove,
  onDelete,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = Boolean(anchorEl)

  let actionSelected = useCallback(callback => () => {
    setAnchorEl(null)
    callback()
  }, [])

  let downloadDisabled = resource.id == 'shared'
  let deleteDisabled = !resource.has('delete')
  let shareDisabled = !resource.has('share')
  let numSelected = useMemo(() => Object.keys(selection).length, [selection])
  let isSelected = useMemo(() => !!selection[resource.id], [selection, resource.id])
  let actionsDisabled = useMemo(() => numSelected > 1 || (numSelected == 1 && !isSelected), [numSelected, isSelected])

  return (
    <div
      className={classNames(styles.container)}>
      {/* checkbox */}
      {onToggleSelection ? (
        <div>
          <input
            type='checkbox'
            className={styles.checkbox}
            onChange={onToggleSelection}
            checked={isSelected}
          />
        </div>
      ) : null}

      {/* icon */}
      <label className={styles.nameWrapper}>
        {resource.type == 'folder'
          ? <FolderIcon width={17.5} height={15} />
        : resource.type == 'image' || resource.type == 'svg'
          ? <MediaImageIcon width={17.5} height={15} />
        : resource.type == 'video'
          ? <VideoImageIcon width={17.5} height={15} />
        : resource.type == 'audio'
          ? <AudioIcon width={17.5} height={15} />
        : <UnknownFileIcon sx={{width: '17.5px', height: '15px', color: '#999'}}/>}
        <a
          title={resource.type == 'folder' ? `Open ${resource.name}` : `Preview file ${resource.name}`}
          className={classNames(styles.name, resource.type === 'folder' && styles.folderName)}
          onClick={onNavigate}
        >
          {resource.name}
        </a>
      </label>

      {/* details */}
      {onToggleSelection && <>
        <p className={styles.dateCreated}>{resource.id != 'shared' ? formatDate(resource.modified.on) : ''}</p>
        <p className={styles.size}>{resource.id != 'shared' ? formatFileSize(resource.usage.size, 2) : ''}</p>
      </>}

      {onDownload || onShare || onCopy || onRename || onMove || onDelete ?
        <div className={styles.actionsContainer}>
          <IconButton
            id={`${resource.id}-more-actions`}
            sx={{ marginRight: '1rem' }}
            aria-controls={`${resource.id}-action-list`}
            aria-haspopup='true'
            aria-expanded={menuOpen ? 'true' : undefined}
            disabled={actionsDisabled}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <ListMoreHoverIcon color={actionsDisabled ? '#ccc': '#333'} />
          </IconButton>
          <Menu
            id={`${resource.id}-action-list`}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{'aria-labelledby': `${resource.id}-more-actions`}}
          >
            {onDownload ? (
              <MenuItem
                onClick={actionSelected(onDownload)}
                disabled={downloadDisabled}
              >download</MenuItem>
            ) : null}

            {onShare ?
              <MenuItem
                onClick={actionSelected(onShare)}
                disabled={shareDisabled}
              >share</MenuItem>
            : null}

            {onCopy ? (
              <MenuItem
                onClick={actionSelected(onCopy)}
                disabled={downloadDisabled}
              >copy</MenuItem>
            ) : null}

            {onRename ? (
              <MenuItem
                onClick={actionSelected(onRename)}
                disabled={deleteDisabled}
              >rename</MenuItem>
            ) : null}

            {onMove ? (
              <MenuItem
                onClick={actionSelected(onMove)}
                disabled={deleteDisabled}
              >move</MenuItem>
            ) : null}

            {onDelete ? (
              <MenuItem
                onClick={actionSelected(onDelete)}
                disabled={deleteDisabled}
              >delete</MenuItem>
            ) : null}
          </Menu>
        </div> : <div className={styles.actionsPlaceholder}></div>}
    </div>
  )
}
