import { useMemo, useState } from 'react'

import CloseIcon from 'components/icons/CloseIcon'
import MaximizeWindowIcon from 'components/icons/MaximizeWindowIcon'
import MinimizeWindowIcon from 'components/icons/MinimizeWindowIcon'
import UploadList from './UploadList'

import styles from './UploadModal.module.scss'


export default function UploadModal({ uploads, setUploads }) {
  let [maximized, setMaximized] = useState(true)

  let [pending, visible] = useMemo(() => [
    uploads.some(({status}) => status == 'pending'),
    uploads.some(({hidden}) => !hidden)
  ], [uploads])

  return visible &&
    <div className={maximized ? styles.containerDefault : styles.containerMinimize}>
      <div className={styles.header}>
        <h2 className={styles.title}>Uploads</h2>
        <div className={styles.icons}>
          <button
            className={maximized ? styles.default : styles.minimize}
            onClick={() => setMaximized(prev => !prev)}
          >
            {maximized ? <MinimizeWindowIcon /> : <MaximizeWindowIcon />}
          </button>
          <button
            className={styles.close}
            onClick={() => setUploads([])}
            disabled={pending} // can't be closed while there are active uploads
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      {maximized && <UploadList uploads={uploads} setUploads={setUploads} />}
    </div>
}
