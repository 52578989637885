const UploadIcon = ({ color = '#383838' }) => {
  return (
    <svg
      width='12px'
      height='15px'
      viewBox='0 0 12 15'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Upload</title>
      <g
        id='Icon/upload'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='Button/upload-orange'
          transform='translate(1.000000, 1.000000)'
          stroke={color}
          strokeWidth='2'
        >
          <g id='Icon/upload-white'>
            <g id='Group-5'>
              <path
                d='M5,5.68434189e-14 L7.5,3.25858161 M5,5.68434189e-14 L2.5,3.25858161 M5,8.38541667 L5,2.55795385e-13'
                id='Combined-Shape'
              ></path>
              <path
                d='M0,12.5520833 L10,12.5520833 L0,12.5520833 Z'
                id='Path'
                transform='translate(5.000000, 12.552083) scale(1, -1) translate(-5.000000, -12.552083) '
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UploadIcon;
