import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { ResourceContext, SessionContext } from 'contexts'
import classNames from 'classnames'

import CircularProgress from '@mui/material/CircularProgress'
import CloudUploadIcon from 'components/icons/CloudUploadIcon'
import MediaBrowserGrid from './grid/MediaBrowserGrid'
import MediaBrowserHeader from './MediaBrowserHeader'
import MediaBrowserList from './list/MediaBrowserList'
import MediaBrowserModalHeader from './MediaBrowserModalHeader'
import MediaPreview from 'components/mediapreview/MediaPreview'
import SelectedMediaActions from './SelectedMediaActions'

import styles from './MediaBrowser.module.scss'


let DEFAULT_FILTER = () => true
let DEFAULT_SORT_KEY = resource => resource.modified.on
let DEFAULT_SORT_ORDER = -1


export default function MediaBrowser({
  location,
  onNavigate,
  filter=DEFAULT_FILTER,
  view='list',
  sortKey=DEFAULT_SORT_KEY,
  sortOrder=DEFAULT_SORT_ORDER,
  preview=() => false,
  onToggleView,
  modalHeight=0,

  // individual file mutators; if none of these are set, then we're rendered in
  // a modal; this means forced list view with custom header and no file details
  // TODO: this should be refactored with context-sensitive components instead
  onShare,
  onCopy,
  onRename,
  onMove,
  onDelete,

  // folder options
  onCreate,
  onUpload,
  onDownload,
  onSort,
}) {
  let { useResource, useResourceChildren } = useContext(ResourceContext)
  let { user } = useContext(SessionContext)

  let [folder, folderLoading] = useResource(location)
  let [children, childrenLoading] = useResourceChildren(location)
  let loading = useMemo(() => folderLoading || childrenLoading, [folderLoading, childrenLoading])
  let [selection, setSelection] = useState({})

  // selection management
  let toggleSelection = useCallback(resource => {
    if(selection[resource.id])
      delete selection[resource.id]
    else
      selection[resource.id] = resource
    setSelection({...selection})
  }, [selection])

  let toggleSelectAll = useCallback(() => {
    let selectAll = Object.keys(selection).length < Object.keys(children).length
    if(selectAll)
      setSelection({...children})
    else
      setSelection({})
  }, [selection, children])

  // children management
  let { contents, status } = useMemo(() => {
    let files = 0
    let folders = 0
    function collect(resource) {
      if(resource.type == 'folder')
        folders++
      else
        files++
      return resource
    }

    // remove deleted files from selection
    let update = false
    for(let key in selection) {
      if(!children[key]) {
        update = true
        delete selection[key]
      }
    }
    update && setSelection({...selection})

    // apply filter, collect statistics and sort by the provided key function
    let contents = Object.values(children).filter(child => filter(child, folder)).sort((a, b) => {
      // the 'shared with me' folder is always first
      if(a.id == 'shared')
        return -1
      if(b.id == 'shared')
        return 1

      // sort folders before files
      if(a.mime != b.mime) {
        if(a.type == 'folder')
          return -1
        if(b.type == 'folder')
          return 1
      }

      // sort by key otherwise
      if(sortKey(a) < sortKey(b))
        return sortOrder * -1
      return sortOrder * 1
    }).map(collect) // mapping after sorting is more cpu cache friendly

    // compose footer message
    let pieces = []
    files && pieces.push(`${files} file${files > 1 ? 's' : ''}`)
    folders && pieces.push(`${folders} folder${folders > 1 ? 's' : ''}`)
    return {contents, status: `Showing ${pieces.join(' and ')}`}
  }, [children, filter, sortKey, sortOrder, selection, folder])

  // this should be refactored, see above
  let isModal = useMemo(() => (
    !(onShare || onCopy || onMove || onDelete)
  ), [onShare, onCopy, onMove, onDelete])

  let isWritable = folder ? (folder.type == 'folder' && folder.has('write')) : false

  // event wrappers
  let onNavigateWrapper = useCallback(target => {
    if(Object.keys(selection).length)
      setSelection({})
    return onNavigate(target)
  }, [onNavigate, selection])

  let onCreateWrapper = useMemo(() => onCreate ? name => {
    return onCreate(folder, typeof name == 'string' ? name : undefined)
  } : null, [folder, onCreate])
  let onUploadWrapper = useMemo(() => onUpload ? files => {
    return onUpload(folder, files instanceof FileList ? files : undefined)
  } : null, [folder, onUpload])

  let timer = useRef(0)
  let [dragging, setDragging] = useState(false)
  const onDragEvent = useCallback(event => {
    event.stopPropagation()
    event.preventDefault()
    if(onUpload) {
      setDragging(true)
    clearTimeout(timer.current)
    timer.current = setTimeout(() => setDragging(false), 500)
    }
  }, [onUpload])
  const onDropEvent = useCallback(event => {
    event.stopPropagation()
    event.preventDefault()
    if(isWritable && onUploadWrapper && event.dataTransfer?.files)
      onUploadWrapper(event.dataTransfer.files)
    clearTimeout(timer.current)
    setDragging(false)
  }, [isWritable, onUploadWrapper])

  return (
    <div
      className={styles.container}
      onDragEnter={onDragEvent}
      onDragOver={onDragEvent}
      onDrop={onDropEvent}
    >
      {/* header */}
      {isModal ? (
        <MediaBrowserModalHeader
          folder={folder}
          onNavigate={onNavigateWrapper}

          onCreate={onCreateWrapper}
          onUpload={onUploadWrapper}
        />
      ) : Object.keys(selection).length ? (
        /* actions on selected items */
        <SelectedMediaActions
          folder={folder}
          selection={selection}
          onAbort={() => setSelection({})}
          onDownload={onDownload ? () => onDownload(selection) : null}

          onShare={onShare ? () => onShare(selection) : null}
          onCopy={onCopy ? () => onCopy(selection) : null}
          onMove={onMove ? () => onMove(selection) : null}
          onDelete={onDelete ? () => onDelete(selection) : null}
        />
      ) : (
        /* path breadcrumbs with default actions */
        <MediaBrowserHeader
          folder={folder}
          view={view}
          onToggleView={onToggleView}
          onNavigate={onNavigateWrapper}

          onCreate={onCreateWrapper}
          onUpload={onUploadWrapper}
        />
      )}

      <div className={styles.dataContainer} style={modalHeight ? {
        height: modalHeight,
        overflow: 'auto'
      } : {}}>
        {/* background progress indicator */}
        {loading && <div className={styles.loadingWrapper}>
          <CircularProgress style={{zIndex: 1}} />
         </div>}
        {contents.length ? (
          preview(folder)
          ? folder && <MediaPreview resource={folder} interactive/>
          : view == 'list'
            ? <MediaBrowserList
                contents={contents}
                selection={selection}
                onNavigate={onNavigateWrapper}
                onDownload={onDownload}
                sortKey={sortKey}
                sortOrder={sortOrder}
                onSort={onSort}

                onToggleSelection={isModal ? null : toggleSelection}
                onToggleSelectAll={isModal ? null : toggleSelectAll}

                onShare={onShare}
                onCopy={onCopy}
                onRename={onRename}
                onMove={onMove}
                onDelete={onDelete}
              />
            : <MediaBrowserGrid
                contents={contents}
                onNavigate={onNavigateWrapper}
                onDownload={onDownload}

                onShare={onShare}
                onCopy={onCopy}
                onRename={onRename}
                onMove={onMove}
                onDelete={onDelete}
              />
        ): !loading &&
          <div className={styles.emptyFolder} style={{height: modalHeight || '200px'}}>
            {/* known empty state: inform user of drag&drop support */}
            {location == user.home ? 'Your home folder ' : 'This folder '}
            is empty.
            {isWritable && <>
              <br/><CloudUploadIcon />
              <br/>If you&apos;d like, you can
              <br/>drag and drop files here
            </>}
          </div>
        }
      </div>
      {!isModal && contents.length ?
        <p style={{ textAlign: 'center', marginTop: '1rem' }}>
          <small>{status}</small>
        </p>
      : null}
      {/* valid drop target indicator */}
      {dragging &&
        <div className={classNames(styles.dropTarget, isWritable ? null : styles.notWritable)}>
          <div className={styles.dropTargetText}>
            {isWritable ? 'Drop files here to upload' : 'You may not upload files in this folder'}
          </div>
        </div>
      }
    </div>
  )
}
