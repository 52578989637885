import UploadItem from './UploadItem'

import styles from './UploadList.module.scss'


export default function UploadList({ uploads, setUploads }) {
  return (
    <div className={styles.container}>
      {uploads.map((upload, i) => upload.hidden ? null :
        <UploadItem
          key={i}
          {...upload}
          onDelete={() => setUploads(prev => prev.filter(item => item.key != upload.key))}
        />
      )}
    </div>
  )
}
