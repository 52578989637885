import Modal from '../modal/Modal'

export default function ConfirmationModal({ confirmation, onClose }) {
  return <Modal
    title={confirmation?.title ?? 'Confirm'}
    open={!!confirmation}
    handleClose={onClose}
    secondaryFooterAction={{
      content: 'Cancel',
      onAction: () => {
        confirmation?.onCancel?.()
        onClose?.()
      },
    }}
    primaryFooterAction={{
      content: 'Confirm',
      onAction: () => {
        confirmation?.onConfirm?.()
        onClose?.()
      },
    }}
  >{confirmation?.message ?? 'Are you sure?'}</Modal>
}
