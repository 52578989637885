import { useMemo } from 'react'
import { formatDate, formatFileSize } from 'utils'

import CopyIcon from '@mui/icons-material/ContentCopyOutlined'
import DownloadIcon from 'components/icons/DownloadIcon'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMoveOutlined'
import IconButton from '@mui/material/IconButton'
import MediaPreview from 'components/mediapreview/MediaPreview'
import RenameIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import ShareIcon from 'components/icons/ShareIcon'
import TrashIcon from 'components/icons/TrashIcon'

import styles from './MediaBrowserGridItem.module.scss'


const NBSP = '\u00a0'
export default function MediaBrowserGridItem({
  resource,
  onNavigate,
  onDownload,

  onShare,
  onCopy,
  onRename,
  onMove,
  onDelete,
}) {
  let downloadDisabled = resource.id == 'shared'
  let deleteDisabled = useMemo(() => !resource.has('delete'), [resource])
  let shareDisabled = useMemo(() => !resource.has('share'), [resource])

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} onClick={onNavigate}>
        <div className={styles.thumbnail}>
          <MediaPreview resource={resource} interactive={false} />
        </div>
        <p className={styles.dateCreated}>{resource.id != 'shared' ? `Last modified on ${formatDate(resource.modified.on)}` : NBSP}</p>
        <p className={styles.size}>{resource.id != 'shared' ? formatFileSize(resource.usage.size, 2) : NBSP}</p>
      </div>
      {onDownload || onShare || onCopy || onRename || onMove || onDelete ? (
        <div className={styles.icons}>
          {onDownload &&
            <IconButton
              size='small'
              onClick={onDownload}
              title='Download'
              disabled={downloadDisabled}
            >
              <DownloadIcon color={downloadDisabled ? '#888' : '#000'}/>
            </IconButton>
          }
          {onShare &&
            <IconButton
              size='small'
              onClick={onShare}
              title='Share'
              disabled={shareDisabled}
            >
              <ShareIcon color={shareDisabled ? '#888' : '#000'}/>
            </IconButton>
          }
          {onRename &&
            <IconButton
              size='small'
              onClick={onRename}
              title='Rename'
              disabled={deleteDisabled}
            >
              <RenameIcon
                sx={{
                  color: deleteDisabled ? '#888' : '#000',
                  fontSize: '20px',
                }}
              />
            </IconButton>
          }
          {onCopy &&
            <IconButton
              size='small'
              onClick={onCopy}
              title='Copy'
              disabled={downloadDisabled}
            >
              <CopyIcon
                sx={{
                  color: downloadDisabled ? '#888' : '#000',
                  fontSize: '20px',
                }}
              />
            </IconButton>
          }
          {onMove &&
            <IconButton
              size='small'
              onClick={onMove}
              title='Move'
              disabled={deleteDisabled}
            >
              <DriveFileMoveIcon
                sx={{
                  color: deleteDisabled ? '#888' : '#000',
                  fontSize: '20px',
                }}
              />
            </IconButton>
          }
          {onDelete &&
            <IconButton
              size='small'
              onClick={onDelete}
              title='Delete'
              disabled={deleteDisabled}
            >
              <TrashIcon  color={deleteDisabled ? '#888' : '#000'}/>
            </IconButton>
          }
        </div>
      ) : null}
    </div>
  )
}
