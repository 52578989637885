import { useCallback, useEffect, useRef } from 'react'

import styles from './MediaBrowserListItemHeader.module.scss'

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export default function MediaBrowserListItemHeader({
  onToggleSelectAll,
  selection,
  contents,
  onSort,
  sortKey,
  sortOrder,
}) {
  const checkboxRef = useRef({})
  useEffect(() => {
    if(checkboxRef) {
      checkboxRef.current.checked = selection.size == contents.length
      checkboxRef.current.indeterminate = 0 < selection.size && selection.size < contents.length
    }
  }, [selection.size, contents.length])

  let sortBy = useCallback(key => () => onSort?.(key), [onSort])
  let sortIcon = useCallback(key => onSort && sortKey.name == key ?
    sortOrder == 1
    ? <ArrowDropUpIcon sx={{ verticalAlign: 'middle', fontSize: '18px' }} />
    : <ArrowDropDownIcon sx={{ verticalAlign: 'middle', fontSize: '18px' }} />
  : null, [onSort, sortKey.name, sortOrder])

  return (
    <div className={styles.container}>
      {onToggleSelectAll ? (
        <div>
          <input
            className={styles.checkbox}
            htmlFor='header-row'
            type='checkbox'
            onChange={onToggleSelectAll}
            ref={checkboxRef}
          />
        </div>
      ) : null}
      <label className={styles.nameWrapper} htmlFor='header-row'>
        <h2 className={styles.name} onClick={sortBy('name')}>Name {sortIcon('name')}</h2>
      </label>
      <h2 className={styles.dateCreated} onClick={sortBy('modified')}>Modified {sortIcon('modified')}</h2>
      <h2 className={styles.size} onClick={sortBy('size')}>Size {sortIcon('size')}</h2>
      <h2 className={styles.actions}>Actions</h2>
    </div>
  )
}
