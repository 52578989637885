import { useState } from 'react'

import FormControl from '@mui/material/FormControl'
import Modal from 'components/modal/Modal'
import TextField from '@mui/material/TextField'

import styles from './RenameModal.module.scss'


export default function RenameModal({
  // customization
  initialValue='',
  title,
  placeholder='',
  description,
  action='Save',

  onConfirm,
  onClose,
}) {
  const [value, setValue] = useState(initialValue)
  const [disabled, setDisabled] = useState(false)

  async function onAction() {
    setDisabled(true)
    try {
      await onConfirm(value)
    } catch(err) {
      console.error(err)
    } finally {
      setDisabled(false)
      onClose()
    }
  }

  return (
    <Modal
      title={title}
      open={true}
      maxWidth={'sm'}
      fullWidth
      handleClose={onClose}
      primaryFooterAction={{
        content: action,
        disabled: disabled || !value,
        onAction,
      }}
    >
      {description && <div className={styles.description}>{description}</div>}
      <FormControl fullWidth>
        <TextField
          className={styles.textField}
          value={value}
          required
          inputRef={self => self && setTimeout(() => self.focus(), 100)}
          onKeyPress={e => e.key == 'Enter' && onAction()}
          placeholder={placeholder}
          onChange={({target}) => setValue(target.value)}
        />
      </FormControl>
    </Modal>
  )
}
