const MediaImageIcon = ({ width = 14, height = 12, color = '#333333' }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox='0 0 14 12'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>media image</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='List/Item-image' transform='translate(-3.000000, -2.000000)'>
          <g id='media-image' transform='translate(3.000000, 2.500000)'>
            <path
              d='M4.36165712,4.62701816 L8.17574091,5.17701967 C8.44905619,5.21643249 8.63867159,5.46994871 8.59925876,5.74326399 C8.58385007,5.8501183 8.53427002,5.94911519 8.45793107,6.02545415 L5.19384879,9.28953643 C4.99858664,9.48479857 4.68200415,9.48479857 4.48674201,9.28953643 C4.41040305,9.21319747 4.360823,9.11420058 4.34541431,9.00734627 L3.7954128,5.19326247 C3.75599998,4.9199472 3.94561538,4.66643098 4.21893065,4.62701816 C4.26626135,4.62019294 4.31432642,4.62019294 4.36165712,4.62701816 Z'
              id='Rectangle'
              fill={color}
              transform='translate(6.431937, 7.263542) rotate(-315.000000) translate(-6.431937, -7.263542) '
            ></path>
            <circle
              id='Oval'
              fill='#383838'
              cx='9.46839464'
              cy='3.7'
              r='1'
            ></circle>
            <path
              d='M12.0555556,-1.95399252e-14 L1.94444444,-1.95399252e-14 C0.870557431,-1.95399252e-14 0,1.01824131 0,2.27430562 L0,8.64236138 C0,9.89842569 0.870557431,10.916667 1.94444444,10.916667 L12.0555556,10.916667 C13.1294426,10.916667 14,9.89842569 14,8.64236138 L14,2.27430562 C14,1.01824131 13.1294426,-1.95399252e-14 12.0555556,-1.95399252e-14 Z M1.94444444,0.90972225 L12.0555556,0.90972225 C12.6998878,0.90972225 13.2222222,1.52066704 13.2222222,2.27430562 L13.2222222,8.64236137 C13.2222222,9.39599996 12.6998878,10.0069447 12.0555556,10.0069447 L1.94444444,10.0069447 C1.30011224,10.0069447 0.777777778,9.39599996 0.777777778,8.64236137 L0.777777778,2.27430562 C0.777777778,1.52066704 1.30011224,0.90972225 1.94444444,0.90972225 Z'
              id='Rectangle'
              fill={color}
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MediaImageIcon;
