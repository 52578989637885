import styles from './ContactUsIcon.module.scss'

const ContactUsIcon = ({ isActive }) => {
  return (
    <div className={styles.container}>
      <svg width="29px" height="23px" viewBox="0 0 29 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <title>contact us</title>
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g className={isActive ? styles.group : ""} id="Main/Sidebar-None-Selected" transform="translate(-72.000000, -170.000000)" fill="#FFFFFF">
                  <g id="Group-9">
                      <g id="Group-8">
                          <g id="Group-6" transform="translate(72.000000, 170.000000)">
                              <g id="contact-us">
                                  <path d="M14.4796357,0.138763198 C5.34569055,0.138763198 0.138095238,3.08485271 0.138095238,8.72936656 L0.139273742,9.00295454 L0.147561598,9.32665783 C0.280248165,12.760854 1.84447668,15.6991151 6.09827,17.3867978 L6.55499429,17.5588175 L6.51363861,17.4008748 C6.56482432,17.6117162 6.63680244,17.843305 6.73323844,18.0904675 C7.00475471,18.7863552 7.41794335,19.4789999 8.00142358,20.1229571 C8.72932071,20.9262998 9.67213525,21.5949784 10.8489783,22.085267 C12.1121514,22.6115218 13.7103334,22.8690723 15.6466488,22.8690723 C16.268862,22.8690723 16.5966158,22.1209771 16.1796884,21.6524138 L15.9566231,21.3972836 C14.3781835,19.5583402 14.0573707,18.4203572 14.6926486,17.7657008 L14.7326629,17.7263741 L14.4796387,17.7292148 C23.5099016,17.7292148 28.8619048,14.3394748 28.8619048,8.72936656 C28.8619048,3.27211869 23.4430792,0.138763198 14.4796357,0.138763198 Z M14.4796357,1.59577677 C22.7550761,1.59577677 27.4257073,4.296504 27.4257073,8.72936656 C27.4257073,13.3533452 22.8171819,16.2722013 14.4796387,16.2722013 C14.3331604,16.2722013 14.1901851,16.3176457 14.069893,16.4024382 L13.8903154,16.5374339 C12.5763581,17.5922122 12.641542,19.1446216 13.887602,21.0515643 L14.084941,21.3426817 L13.6965514,21.3030545 C12.8044389,21.1944071 12.0368807,21.0050774 11.3945517,20.7374745 C10.4130761,20.3285786 9.64477411,19.7836708 9.05877017,19.1369283 C8.59998931,18.6305953 8.27797287,18.0907863 8.06861955,17.5542203 C8.02070732,17.4314227 7.98139765,17.3153975 7.94982402,17.2076546 L7.89340022,16.9885298 L7.87675268,16.9031327 C7.84019473,16.6266285 7.65031652,16.395169 7.38824789,16.3082591 C3.02493215,14.8612517 1.61670991,12.2151543 1.57539664,8.99028493 L1.57427878,8.72616425 C1.57427878,4.11843101 6.03340427,1.59577677 14.4796357,1.59577677 Z" id="Oval" fillRule="nonzero"></path>
                                  <ellipse id="Oval" cx="9.43670795" cy="9.17224736" rx="1.43619048" ry="1.45701357"></ellipse>
                                  <ellipse id="Oval" cx="14.4633746" cy="9.17224736" rx="1.43619048" ry="1.45701357"></ellipse>
                                  <ellipse id="Oval" cx="19.4900413" cy="9.17224736" rx="1.43619048" ry="1.45701357"></ellipse>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    </div>
  );
}

export default ContactUsIcon;

