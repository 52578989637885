export function formatDate(date) {
  if (!date) return ''
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}


export function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return '0 Bytes';
  const dm = decimalPoint || 0;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1000));
  return parseFloat((bytes / Math.pow(1000, i)).toFixed(dm)) + ' ' + sizes[i];
}


export const formatDuration = (seconds) => {
  const date = new Date(seconds * 1000).toISOString();
  if (seconds < 3600) {
    return date.substring(14, 19);
  } else {
    new Date(seconds * 1000).toISOString().substr(11, 8);
    return date.substring(11, 19);
  }
};


/* this is used by the data providers to attach extra logic to dumb data objects
in a way that still makes them cachable; the tl;dr; is that JSON.stringify()
will ignore functions and non-enumerable properties, so this is used to attach
functions and memoized values when unserializing an object from the cache or
network; this function is idempotent and is frequently used in combination with
useMemo() to update the logic based on new data (e.g. server config) */
export const patchObject = (obj, methods, props) => obj && Object.defineProperties(
  Object.assign(obj, methods), // functions are not JSON serializable
  Object.fromEntries(Object.entries(props).map(([key, val]) => [key, {
    configurable: true, // we want to replace this later
    value: typeof(val) == 'function' ? val.apply(obj) : val // cache getter value once
  }]))
)


export class TaskQueue extends Map {
  #first = set => set.keys().next().value
  constructor() {
    super()
    this.pending = new Set() // pending items
    this.workers = new Set() // pending workers
  }
  set(key, value) {
    /* adds or updates a task to the queue; if the task was not already enqueued
    and there's an available worker, it is notified; if the task was already
    enqueued, then the worker processing it is not interrupted but it can at its
    leisure check the updated state via a `get` call; if there are no available
    workers, it will be processed FIFO */
    let added = !this.has(key)
    super.set(key, value)
    if(added)
      this.notify(key)
    return this
  }
  delete(key) {
    /* removes task from the queue; if a worker is already processing it, then
    it is not interrupted, otherwise no other worker will pick this up; usually
    called by workers to signal that a task has been completed */
    this.pending.delete(key)
    return super.delete(key)
  }
  clear() {
    /* removes all tasks from the queue and shuts down all idling workers;
    active workers are not interrupted */
    this.pending.clear() // flush pending queue
    this.workers.forEach(res => res()) // tell workers to shut down
    this.workers.clear() // discard workers
    return super.clear()
  }
  next() {
    /* returns a promise that resolves to the next enqueued item, or undefined
    if the queue is cleared; always called by workers to get a new task */
    return new Promise(res => {
      if(this.pending.size) {
        let key = this.#first(this.pending)
        this.pending.delete(key)
        res(key)
      } else
        this.workers.add(res)
    })
  }
  notify(key) {
    /* wakes up the first available worker with this key; if no workers are
    available, it will be added to the queue; `key` should be already enqueued;
    this should be called by all workers that are refusing to process a message
    that they have received via `next`, i.e. this is `nack` */
    let res = this.#first(this.workers)
    if(res) {
      this.workers.delete(res)
      res(key)
    } else
      this.pending.add(key)
  }
}
