import { useContext, useState } from 'react'
import { ApiContext, SessionContext } from 'contexts'

import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Modal from 'components/modal/Modal'
import TextField from '@mui/material/TextField'

import styles from './AuthorizationModal.module.scss'


export default function AuthorizationModal({ onSuccess, onClose }) {
  let { api } = useContext(ApiContext)
  let { cacheSession } = useContext(SessionContext)

  let [error, setError] = useState(null)
  let [loading, setLoading] = useState(false)
  let [password, setPassword] = useState('')
  return (
    <Modal
      title='Confirm identity'
      maxWidth='sm'
      open={true}
      handleClose={onClose}
      secondaryFooterAction={{
        content: 'Abort',
        onAction: onClose,
      }}
      primaryFooterAction={{
        content: 'Continue',
        loading,
        disabled: !password,
        async onAction() {
          setLoading(true)
          try {
            cacheSession(await api('POST', 'account/refresh/', {password}, {sign: true}))
            onSuccess()
          } catch(err) {
            console.error(err)
            setError(err.code == 'password.invalid' ? 'Invalid password' :
              `Apologies for the inconvenience, but we are unable to confirm
               your identity  at this time due to technical difficulties.
               Please try again later or contact us if the issue persists.`
            )
          } finally {
            setLoading(false)
          }
        }
      }}
    >
      <p>The action you are about to take requires you to prove your identity. Please enter your password below:</p>
      <Divider style={{margin: '10px 0'}}/>
      {error ? <Alert sx={{ width: 1 }} severity='error'>{error}</Alert> : null}
      <FormControl required fullWidth className={styles.formControl}>
        <label htmlFor='password'>Password</label>
        <TextField
          value={password}
          onChange={event => setPassword(event.target.value)}
          name='password'
          type='password'
          variant='outlined'
        />
      </FormControl>
    </Modal>
  )
}
