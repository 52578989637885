import { useCallback, useContext } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import Router from 'next/router'
import TheatersIcon from '@mui/icons-material/Theaters';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import { AppContext, SessionContext } from 'contexts'
import ContactUsIcon from 'components/icons/ContactUsIcon'
import Modal from 'components/modal/Modal'
import MyMediaIcon from 'components/icons/MyMediaIcon'
import SignOutIcon from 'components/icons/SignOutIcon'
import TutorialsIcon from 'components/icons/TutorialsIcon'

import styles from './Sidebar.module.scss'


export default function Sidebar() {
  const { navOpen, setNavOpen, contactOpen, setContactOpen } = useContext(AppContext)
  const { flushSession } = useContext(SessionContext)
  let { user } = useContext(SessionContext)
  let path = Router.asPath

  let logout = useCallback(e => {
    e.preventDefault()
    flushSession()
    Router.push('/')
  }, [flushSession])

  return (<>
    <div className={navOpen ? styles.containerActive : styles.container}>
      <div className={styles.logoWrapper} onClick={() => navOpen ? setNavOpen(false) : Router.push('/')}>
        <div className={styles.logo}>
          <Image
            src='/images/logo-white.png'
            alt='Padcaster Portal'
            loader={x => x.src}
            width={200}
            height={36}
          />
        </div>
      </div>

      <Link href={`/media/${user.home}`}>
        <a className={!contactOpen && path.startsWith('/media') ? styles.linkActive : styles.link}>
          <span className={styles.icon}><MyMediaIcon isActive={!contactOpen && path.startsWith('/media')} /></span>
          <span className={styles.text}>My Media</span>
        </a>
      </Link>
      <Link href={`/tutorials`}>
        <a className={!contactOpen && path.startsWith('/tutorials') ? styles.linkActive : styles.link}>
          <span className={styles.icon}><TutorialsIcon isActive={!contactOpen && path.startsWith('/tutorials')} /></span>
          <span className={styles.text}>Tutorials</span>
        </a>
      </Link>
      <Link href={`/assets`}>
        <a className={!contactOpen && path.startsWith('/assets') ? styles.linkActive : styles.link}>
          <TheatersIcon classes={{root: styles.icon}} color={!contactOpen && path.startsWith('/assets') ? 'primary' : 'inherit'}/>
          <span className={styles.text}>Downloadable Assets</span>
        </a>
      </Link>
      {user.isStaff ? (
        <Link href={`/stats`}>
          <a className={path.startsWith('/stats') ? styles.linkActive : styles.link}>
            <QueryStatsIcon classes={{root: styles.icon}} color={path.startsWith('/stats') ? 'primary' : 'inherit'}/>
            <span className={styles.text}>Stats</span>
          </a>
        </Link>
      ) : (
        <a className={contactOpen ? styles.linkActive : styles.link} onClick={() => setContactOpen(true)}>
          <span className={styles.icon}><ContactUsIcon isActive={contactOpen}/></span>
          <span className={styles.text}>Contact Us</span>
        </a>
      )}
      

      <div className={styles.divider}></div>
      <a className={styles.linkActive} onClick={logout}>
        <span className={styles.icon}><SignOutIcon isActive={true} /></span>
        <span className={styles.text}>Sign Out</span>
      </a>
    </div>
    <Modal
      open={contactOpen}
      title='Contact Sales Agent'
      fullWidth
      maxWidth='lg'
      secondaryFooterAction={false}
      handleClose={() => setContactOpen(false)}
    >
      <iframe className={styles.contact} src='https://form.jotform.com/230114864450146' allowtransparency="true"/>
    </Modal>
  </>)
}
