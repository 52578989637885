import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { ApiContext, CacheContext, SessionContext } from 'contexts'
import { ARTICLE_LIFETIME } from 'constants'
import { TaskQueue } from 'utils'


function useArticle(id, staleAfter=ARTICLE_LIFETIME) {
  let { useCache } = useContext(CacheContext)
  let { _queue, _articleKeyFor } = useContext(CMSContext)
  let result = useCache(_articleKeyFor(id), staleAfter)

  // reload when stale
  let stale = result[1]
  useEffect(() => {
    stale && _queue.set(id, true)
  }, [id, stale, /*immutable: */ _queue])

  return result
}


function useCategory(id, staleAfter=ARTICLE_LIFETIME) {
  let [categories, stale, error] = useCMS(staleAfter)

  return useMemo(() => {
    for(let category of categories)
      if(category.id == id)
        return [category, stale, error]

    if(!stale) {
      let error = new Error(`Invalid category id ${id}`)
      error.code = 'category.invalid'
      return [null, stale, error]
    }
  }, [id, categories, stale, error])
}


function useSubcategory(id, staleAfter=ARTICLE_LIFETIME) {
  let [categories, stale, error] = useCMS(staleAfter)

  return useMemo(() => {
    for(let category of categories)
      for(let subcategory of category.subcategories)
        if(subcategory.id == id)
          return [subcategory, stale, error]

    if(!stale) {
      let error = new Error(`Invalid subcategory id ${id}`)
      error.code = 'subcategory.invalid'
      return [null, stale, error]
    }
  }, [id, categories, stale, error])
}

function useCMS(staleAfter=ARTICLE_LIFETIME) {
  let { useCache } = useContext(CacheContext)
  let { _refresh, _cmsKey } = useContext(CMSContext)
  let result = useCache(_cmsKey(), staleAfter, [])

  // reload when stale
  let stale = result[1]
  useEffect(() => {
    stale && _refresh()
  }, [stale, /*immutable: */ _refresh])

  return result
}


export const CMSContext = createContext()
export default function CMSProvider({children}) {
  let { iter, useApiEffect } = useContext(ApiContext)
  let cache = useContext(CacheContext)
  let { user } = useContext(SessionContext)

  // cms cache management; namespaced separately for admins and regular users
  // because admins have access to more data; the tutorial tree structure is
  // stored in a single key and refreshed atomically
  let _suffix = user?.isStaff ? '-admin' : ''
  let _cmsKey = useCallback(() => 'cms' + _suffix, [_suffix])
  let _articleKeyFor = useCallback(article => (article?.id ?? article) + _suffix, [_suffix])

  let { cacheArticle, flushArticle, flushCMS } = useMemo(() => ({
    cacheArticle(article) {
      cache.store(_articleKeyFor(article), article)
    },
    flushArticle(article) {
      cache.store(_articleKeyFor(article), null)
      flushCMS() // article may have been featured
    },
    flushCMS() {
      cache.expire(_cmsKey())
    }
  }), [_articleKeyFor, _cmsKey, cache])

  // we use a queue for articles...
  let _queue = useMemo(() => new TaskQueue(), [])
  useApiEffect(() => async api => {
    let id
    // eslint-disable-next-line no-cond-assign
    while(id = await _queue.next()) {
      console.log('fetching article', id)
      try {
        cacheArticle(await api('GET', `cms/${user.isStaff ? '' : 'public/'}articles/${id}/`))
        _queue.delete(id)
      } catch(err) {
        if(err instanceof DOMException && err.name == 'AbortError')
          return _queue.notify(id)
        cache.error(_articleKeyFor(id), err)
        _queue.delete(id)
      }
    }
  }, [/*immutable: */ _queue, cacheArticle, _articleKeyFor, cache, user?.isStaff])

  // ...and a flip-flop for the public cms metadata (categories and subcategories)
  // TODO: refactor this as it's fairly slow for staff members (where it's quite
  // common because the cache is flushed on every update)
  let [stale, setStale] = useState(false)
  let _refresh = useCallback(() => setStale(true), [])
  useApiEffect(() => stale && (async api => {
    console.log(`fetching cms`)
    let key = _cmsKey()
    let cms
    try {
      if(user.isStaff) {
        // fetch categories
        let map = (await iter(api, 'cms/categories/')).reduce((map, category) => {
          category.subcategories = []
          return map.set(category.id, category)
        }, new Map())
        for(let subcategory of await iter(api, 'cms/subcategories/'))
          map.get(subcategory.category).subcategories.push(subcategory)

        // fetch subcategories
        cms = [...map.values()].sort((a, b) => a.priority < b.priority ? 1 : -1)
        map.clear()
        for(let category of cms)
          category.subcategories.sort((a, b) => a.priority < b.priority)
                                .forEach(sub => map.set(sub.id, sub.articles = []))

        // fetch articles
        for(let article of await iter(api, 'cms/articles/')) {
          cacheArticle(article)
          map.get(article.subcategory).push([article.priority, article.id])
        }

        // sort by priority, then by id, then replace with ids and finally truncate
        for(let featured of map.values()) {
          featured.sort((a, b) => {
            if(a[0] == b[0])
              return a[1] < b[1] ? 1 : -1
            return a[0] < b[0] ? 1 : -1
          }).splice(0, featured.length, ...featured.slice(0, 6).map(x => x[1]))
        }
      } else {
        // non-staff members fetch the public page with a single request
        cms = await api('GET', 'cms/')
        for(let category of cms) {
          for(let subcategory of category.subcategories) {
            let ids = []
            // cache article separately and only store ids in the main key
            // these are already sorted by the server so the list is good to go
            for(let article of subcategory.articles) {
              ids.push(article.id)
              cacheArticle(article)
            }
            subcategory.articles = ids
          }
        }
      }

      // save value
      cache.store(key, cms)
      setStale(false)
    } catch(err) {
      if(err instanceof DOMException && err.name == 'AbortError') return
      console.log(err)

      // notify error
      cache.error(key, err)
      setStale(false)
    }
  }), [stale, user?.isStaff, _cmsKey, cacheArticle, /*immutable: */ cache, iter])

  let value = useMemo(() => ({
    // hook helpers
    _refresh,
    _queue,
    _cmsKey,
    _articleKeyFor,

    // public api
    useArticle,
    useCategory,
    useSubcategory,
    useCMS,
    cacheArticle,
    flushArticle,
    // currently not implemented because the cms is fetched in bulk
    cacheCategory: flushCMS,
    flushCategory: flushCMS,
    cacheSubcategory: flushCMS,
    flushSubcategory: flushCMS,
    flushCMS,
  }), [_refresh, _queue, _cmsKey, _articleKeyFor, cacheArticle, flushArticle, flushCMS])
  return <CMSContext.Provider value={value}>{children}</CMSContext.Provider>
}
