import { useContext } from 'react'
import { AppContext } from 'contexts/AppContext'

//import HeaderSearch from './HeaderSearch'
import HeaderSidebarFlyout from './HeaderSidebarFlyout'
import HeaderUser from './HeaderUser'

import styles from './Header.module.scss'


export default function Header(/*{ hasSearchBar }*/) {
  let { navOpen } = useContext(AppContext)

  return (
    <div className={navOpen ? styles.containerNavOpen : styles.container}>
      <HeaderSidebarFlyout />
      {/*hasSearchBar && <HeaderSearch />*/}
      <HeaderUser />
    </div>
  )
}
