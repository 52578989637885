const VideoImageIcon = ({ width = 14, height = 12, color = '#333333' }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox='0 0 14 12'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>video image</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='List/Item-video'
          transform='translate(-3.000000, -2.000000)'
          fill={color}
        >
          <g id='video-image' transform='translate(3.000000, 2.500000)'>
            <path
              d='M3.89326247,2.92701816 L7.70734627,3.47701967 C7.98066154,3.51643249 8.17027695,3.76994871 8.13086412,4.04326399 C8.11545543,4.1501183 8.06587538,4.24911519 7.98953643,4.32545415 L4.72545415,7.58953643 C4.530192,7.78479857 4.21360951,7.78479857 4.01834736,7.58953643 C3.94200841,7.51319747 3.89242836,7.41420058 3.87701967,7.30734627 L3.32701816,3.49326247 C3.28760533,3.2199472 3.47722073,2.96643098 3.75053601,2.92701816 C3.79786671,2.92019294 3.84593177,2.92019294 3.89326247,2.92701816 Z'
              id='Rectangle'
              transform='translate(5.963542, 5.563542) rotate(-225.000000) translate(-5.963542, -5.563542) '
            ></path>
            <path
              d='M12.0555556,0 L1.94444444,0 C0.870557431,0 0,1.01824131 0,2.27430563 L0,8.64236138 C0,9.89842569 0.870557431,10.916667 1.94444444,10.916667 L12.0555556,10.916667 C13.1294426,10.916667 14,9.89842569 14,8.64236138 L14,2.27430563 C14,1.01824131 13.1294426,0 12.0555556,0 Z M1.94444444,0.90972225 L12.0555556,0.90972225 C12.6998878,0.90972225 13.2222222,1.52066704 13.2222222,2.27430562 L13.2222222,8.64236137 C13.2222222,9.39599996 12.6998878,10.0069447 12.0555556,10.0069447 L1.94444444,10.0069447 C1.30011224,10.0069447 0.777777778,9.39599996 0.777777778,8.64236137 L0.777777778,2.27430562 C0.777777778,1.52066704 1.30011224,0.90972225 1.94444444,0.90972225 Z'
              id='Rectangle'
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VideoImageIcon;
