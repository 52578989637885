import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { CacheContext } from 'contexts'
import { APP_VERSION, NAVBAR_HIDE_DELAY, SNACK_HIDE_DELAY } from 'constants'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import ConfirmationModal from 'components/confirmation-modal/ConfirmationModal'
import LinearProgress from '@mui/material/LinearProgress'
import LoadingScreen from 'components/common/LoadingScreen'
import Snackbar from '@mui/material/Snackbar'

// removing this causes the dumbest FOUC in production (but not dev) because
// next seems to forget to load the stylesheets for this component (likely
// because we use it statefully)
import 'components/wrapper/Wrapper'

export const AppContext = createContext()
export default function AppProvider({ children }) {
  //console.log('app refresh')
  // clear cache on version change
  let cache = useContext(CacheContext)
  let [version] = cache.useCache('version')
  if(version !== APP_VERSION) {
    console.log('flushing cache')
    cache.flush()
    cache.store('version', APP_VERSION)
    window.location.reload()
  }

  // the default wrapper is the loading screen; this is currently overridden in
  // two situations: when the login form is shown and when a private page is
  // shown; the only purpose of this stateful wrapper is to prevent FOUC-like
  // behavior whie loading, using last-write-wins for conflict resolution
  let [LoadingWrapper, setLoadingWrapper] = useState(() => LoadingScreen)

  // snack (toast) interface
  const [snack, setSnack] = useState(null)
  const showSnack = useCallback(({ message, status, title }) => {
    if(status == 'error' && !message)
      message = `Apologies for the inconvenience, but we are experiencing
                 some technical difficulties; please try again later.`
    setSnack({ message, status: status || 'info', title })
  }, [])
  const closeSnack = useCallback(() => setSnack(null), [])

  // contact modal, exposed in the sidebar but can also be invoked on demand
  const [contactOpen, setContactOpen] = useState(false)

  // keep track of page transitions
  let router = useRouter()
  const [navigating, setNavigating] = useState(false)
  useEffect(() => {
    function start(url) {
      console.log('Transitioning to', url)
      setNavigating(true)
    }
    function error(err, url) {
      console.log('Transition failed', url)
      setNavigating(false)
    }
    function complete(url) {
      console.log('Transition complete', url)
      setNavigating(false)
    }

    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeError', error)
    router.events.on('routeChangeComplete', complete)
    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeError', error)
      router.events.off('routeChangeComplete', complete)
    }
  }, [router])

  // other modals
  const [confirmation, setConfirmation] = useState()

  // trigger a download in a hidden iframe
  let triggerDownload = useCallback(url => {
    // create a new iframe and position it outside of the visible window
    let frame = document.createElement('iframe')
    frame.style.position = 'absolute'
    frame.style.left = frame.style.top = '-5000px'
    frame.style.width = frame.style.height = '100px'

    // attach to document and set url triggering the download
    document.body.appendChild(frame)
    frame.src = url

    // remove the frame after 10 seconds
    setTimeout(() => document.body.removeChild(frame), 10_000)
  }, [])

  // hide navbar while transitioning between pages
  const [navOpen, setNavOpen] = useState(false)
  useEffect(() => {
    if (navigating && navOpen)
      setTimeout(() => setNavOpen(false), NAVBAR_HIDE_DELAY)
  }, [navigating, navOpen])

  return (
    <AppContext.Provider
      value={{
        LoadingWrapper,
        setLoadingWrapper,
        navOpen,
        setNavOpen,
        snack,
        closeSnack,
        showSnack,
        setConfirmation,
        triggerDownload,
        contactOpen,
        setContactOpen,
      }}
    >
      {navigating ? <LinearProgress sx={{
        // the progress bar should be visible and not reflow the page layout
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }} /> : null}
      {children}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!snack}
        autoHideDuration={SNACK_HIDE_DELAY}
        onClose={closeSnack}
        message={snack?.message}
      >
        <Alert severity={snack?.status} onClose={closeSnack}>
          {snack?.title ? <AlertTitle>{snack?.title}</AlertTitle> : null}
          {snack?.message}
        </Alert>
      </Snackbar>

      {confirmation ? (
        <ConfirmationModal
          confirmation={confirmation}
          onClose={() => setConfirmation(null)}
        />
      ) : null}
    </AppContext.Provider>
  )
}
