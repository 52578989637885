import { createContext, useCallback, useContext, useMemo } from 'react'
import { useRouter } from 'next/router'

import { ApiContext, AppContext, CacheContext } from 'contexts'
import { SESSION_LIFETIME, PUBLIC_PATHS } from 'constants'

import AuthWrapper from 'components/authentication/auth-wrapper/AuthWrapper'
import LoginForm from 'components/authentication/LoginForm'


export const AccountContext = createContext()
export default function AccountProvider({ children }) {
  /* The account context is the first step in the login phase; it is responsible
  with authenticating a user as the legitimate owner of an email address and
  will render the login page if it is unable to do so; the session context will
  then ensure that the current account is bound to a team before proceeding.

  The main reason for this split is that the (public) Session context depends on
  the Team and Member contexts, which in turn depend on being able to perform
  at least some api calls.

  DO NOT USE THIS CONTEXT! SessionContext exposes the same data and methods but
  with a better API. */
  //console.log('account refresh')
  let { LoadingWrapper } = useContext(AppContext)
  let { api, useApiEffect } = useContext(ApiContext)
  let { useCache, store } = useContext(CacheContext)

  // session cache management
  let cacheSession = useCallback(session => {
    store('token', session.token)
    store('session', session)
  }, [/*immutable: */ store])

  let flushSession = useCallback(() => {
    api('POST', 'account/logout/').catch(err => console.error(err))
    store('token', null)
    store('session', null)
    store('challenge', null)
  }, [/*immutable: */ store, api])

  // refresh session when stale, discard session when token is nullified (this
  // happens if the api context gets a session.expired error)
  let [token] = useCache('token')
  let [session, stale] = useCache('session', SESSION_LIFETIME, null)
  useApiEffect(() => {
    if(token && stale)
        return api => api('GET', 'account/').then(cacheSession).catch(err => err.code && flushSession())
    else if(!token)
      flushSession()
  }, [token, stale, /*immutable: */ cacheSession, flushSession])

  // allow some public pages to pass through even if we're not bound to a session
  let router = useRouter()
  let isPublic = useMemo(() => PUBLIC_PATHS.has(router.asPath.split('?')[0]), [router])

  let value = useMemo(() => ({
    session,
    cacheSession,
    flushSession,
    isPublic
  }), [session /*immutable: */, cacheSession, flushSession, isPublic])

  return <AccountContext.Provider value={value}>
    {session || isPublic ? children :
      token
       ? <LoadingWrapper>Logging you in</LoadingWrapper>
       : <AuthWrapper><LoginForm/></AuthWrapper>
    }
    </AccountContext.Provider>
}
